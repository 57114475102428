import { variantProvider } from '..'
import { TextEditorComposition, TextEditorPresets } from '@codeleap/web'
import { assignTextStyle } from '@codeleap/common'

const createTextEditorStyle =
  variantProvider.createVariantFactory<TextEditorComposition>()

const editorStyles = {
  default: createTextEditorStyle(theme => ({
    wrapper: {
      ...theme.spacing.paddingRight(2),
      ...theme.spacing.paddingLeft(3),
      ...theme.spacing.paddingVertical(2),
      fontFamily: theme.typography.base.fontFamily,
      fontSize: theme.typography.base.styles.p1.size,
      fontWeight: theme.typography.base.styles.p1.weight,
      borderBottomLeftRadius: theme.borderRadius.small,
      borderBottomRightRadius: theme.borderRadius.small,
      ...theme.border.neutral5({ width: 1, directions: ['bottom', 'left', 'right'] }),

      // display: 'grid', // this may cause bugs

      h1: { fontSize: theme.typography.base.styles.h1.size },
      h2: { fontSize: theme.typography.base.styles.h2.size },
      h3: { fontSize: theme.typography.base.styles.h3.size },
      h4: { fontSize: theme.typography.base.styles.h4.size },
      h5: { fontSize: theme.typography.base.styles.h5.size },
      h6: { fontSize: theme.typography.base.styles.h6.size },
      'ul, ol': {
        ...theme.spacing.marginLeft(3),
      },
      p: {
        fontSize: theme.typography.base.styles.p1,
        wordBreak: 'break-word',
        height: 'fit-content',
      },
      'p:empty::before': {
        content: `''`,
        display: 'inline-block',
      },

      'p.placeholder::before': {
        color: theme.colors.neutral7,
        content: 'attr(data-placeholder)',
        float: 'left',
        height: 0,
        pointerEvents: 'none',
      },
      img: {
        maxWidth: '50%',
        height: 'auto',
        objectFit: 'contain',
      },
    },
  })),
}

export const TextEditorStyles = {
  ...TextEditorPresets,
  default: createTextEditorStyle((theme) => ({
    wrapper: {
      ...theme.presets.flex,
      ...theme.presets.column,
    },
    'wrapper:error': {
      '.ProseMirror:focus': {
        outline: `auto 2px ${theme.colors.destructive2}`,
      },
    },
    editor: {
      ...editorStyles.default(theme).wrapper,
    },
    'editor:disabled': {
      ...editorStyles.default(theme).wrapper,
      cursor: 'not-allowed',
      ...theme.border.neutral2({ width: 1, directions: ['bottom', 'left', 'right'] }),
      color: theme.colors.neutral5,
    },
    'editor:error': {
      ...editorStyles.default(theme).wrapper,
      ...theme.border.destructive2({ width: 1, directions: ['bottom', 'left', 'right', 'top'] }),
    },
    'errorMessage:error': {
      ...assignTextStyle('p4')(theme).text,
      color: theme.colors.destructive2,
      ...theme.spacing.marginTop(1),
    },
    floatingMenu: {},
    bubbleMenu: {},
    bubbleMenuInnerWrapper: {},
    floatingMenuInnerWrapper: {},
  })),
  flat: createTextEditorStyle((theme) => ({
    editor: {
      border: 'none',
    },
    'editor:disabled': {
      border: 'none',
    },
    'editor:error': {
      border: 'none',
    },
  })),
  'flat:disabled': createTextEditorStyle((theme) => ({
    'wrapper:disabled': {
      color: 'initial',
    },
    'editor:disabled': {
      color: 'initial',
    },
  })),
  disclaimerModalTextEditor: createTextEditorStyle((theme) => ({
    editor: {
      ...editorStyles.default(theme).wrapper,
      minHeight: 180,
      [theme.media.down('mid')]: {
        minHeight: 250,
      },
    },
  })),
  reviewerQuestionsForm: createTextEditorStyle((theme) => ({
    editor: {
      minHeight: 200,
      // maxHeight: 200,
    },
  })),
  reviewOverviewForm: createTextEditorStyle((theme) => ({
    editor: {
      minHeight: 115,
      backgroundColor: theme.colors.neutral1,
      borderRadius: theme.borderRadius.tiny,
      borderColor: theme.colors.neutral5,
    },
  })),
  reviewCommentModal: createTextEditorStyle((theme) => ({
    wrapper: {
      borderBottomLeftRadius: theme.borderRadius.tiny,
      borderBottomRightRadius: theme.borderRadius.tiny,
    },
    editor: {
      minHeight: 200,
      borderBottomLeftRadius: theme.borderRadius.tiny,
      borderBottomRightRadius: theme.borderRadius.tiny,
    },
  })),
  'editor:adaptiveHeight': createTextEditorStyle((theme) => ({
    editor: {
      ...editorStyles.default(theme).wrapper,
      minHeight: 120,

      [theme.media.down('tabletSmall')]: {
        minHeight: 200,
      },
    },
  })),
  announcements: createTextEditorStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(0),
    },
    editor: {
      ...theme.spacing.padding(0),

    },
    'editor:disabled': {
      ...theme.spacing.padding(0),
      color: theme.colors?.neutral9,
      cursor: 'default',
    },
    'editor:error': {
      ...theme.spacing.padding(0),

    },

  })),
  footer: createTextEditorStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(0),

      'a': {
        color: theme.colors.neutral1,
        fontWeight: '700',
      },
    },
    editor: {
      ...theme.spacing.padding(0),

    },
    'editor:disabled': {
      ...theme.spacing.padding(0),
      color: theme.colors?.neutral4,
      cursor: 'default',
    },
    'editor:error': {
      ...theme.spacing.padding(0),

    },

  })),
  bare: createTextEditorStyle((theme) => ({
    editor: {
      ...editorStyles.default(theme).wrapper,
      minHeight: 'initial',
      ...theme.spacing.padding(0),
    },
  })),
  disabledPlain: createTextEditorStyle((theme) => ({
    'editor:disabled': {
      color: theme.colors?.neutral9,
      cursor: 'default',
    },
  })),
}
