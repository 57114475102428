import { ActivityIndicator, Text, View } from '@/components'
import { APIClient } from '@/services'
import { onUpdate } from '@codeleap/common'
import { useState } from 'react'

export function ReviewerMetrics({ version, article }) {
  const [metrics, setMetrics] = useState(null)

  onUpdate(() => {
    setMetrics(null)
    if (version && article) {
      APIClient.Publications.getReviewersMetrics({ version, article }).then(setMetrics)
    }
  }, [version, article])

  const _metrics = [
    { title: 'Reviewers invited', value: metrics?.reviewers_invited ?? 0 },
    { title: 'Invites accepted', value: metrics?.invites_accepted ?? 0 },
    { title: 'Reviews done', value: metrics?.reviews_submitted ?? 0 },
  ]

  return (
    <View variants={['padding:2', 'gap:2', 'bg:neutral2', 'border-radius:tiny']}>
      {!metrics ? (
        <ActivityIndicator variants={['marginHorizontal:auto']} debugName='reviewer metrics activity indicator' />
      ) : (
        _metrics.map(({ title, value }) => (
          <View variants={['column', 'gap:1', 'fullWidth']}>
            <Text variants={['p2', 'color:neutral8']} text={title} />
            <Text variants={['h5', 'color:neutral9']} text={`${value}`} />
          </View>
        ))
      )}
    </View>
  )
}
