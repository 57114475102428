import { OSAlert, React, Theme } from '@/app'
import { AppStatus, useAppSelector } from '@/redux'
import { ActionIcon, ActivityIndicator, BlindfoldedToaster, Button, FileInput, Icon, Modal, Text, View } from '..'
import { APIClient } from '@/services'
import { onUpdate, TypeGuards, useMemo, useRef, useState } from '@codeleap/common'
import { downloadFileFromURL, formatFileName, useFileCategoryOptions } from '@/utils'
import { fileSizeValidator } from '@/app/forms'

export const BlindfoldedJournalFlow = () => {

  const { visible, data } = useAppSelector((state) => state.AppStatus.modals.blindfoldedJournalFlow)

  const fileInputRef = useRef(null)

  const [validateMainManuscript, setValidateMainManuscript] = useState(false)
  const [initialFiles, setInitialFiles] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [canUploadFile, setCanUploadFile] = useState(false)

  const {
    data: publication,
    query: {
      isLoading: isLoadingPublication,
    },
  } = APIClient.Publications.publicationsManager.useItem({
    id: data?.publication, queryOptions: {
      cacheTime: 0,
      enabled: visible,
      refetchOnMount: false,
    },
  })

  const shouldDisplayRevisionCategories = publication?.version > 1

  const file_categories = useFileCategoryOptions({
    show_on_revision: shouldDisplayRevisionCategories,
    show_on_submission: !shouldDisplayRevisionCategories,
  })

  const {
    items: articleMediaList,
    list: { query: { isLoading: isLoadingArticleMedia }},
  } = APIClient.Publications.mediaManager.use({
    filter: {
      publication: data?.publication,
    },
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        enabled: visible,
      },
    },
  })

  const lastReply = publication?.versions?.find?.(item => item?.article_version === publication?.version)

  const {
    items: replyMediaList,
    list: { query: { isLoading: isLoadingReplyMedia }},
  } = APIClient.Replica.replicaMediaManager.use({
    filter: {
      reply: lastReply?.id,
    },
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        enabled: visible,
      },
    },
  })

  const amountInitialState = canUploadFile ? 0 : initialFiles?.length

  const [updatedAmount, setUpdatedAmount] = useState(amountInitialState)

  const hasUploadedAllFiles = initialFiles.length === updatedAmount

  const submitButtonDisabled = !hasUploadedAllFiles || !canUploadFile

  const isFirstPublicationVersion = publication?.version === 1

  const isLoadingMedia = isFirstPublicationVersion ? isLoadingArticleMedia : isLoadingReplyMedia

  onUpdate(() => {
    const _files = []
    if (isFirstPublicationVersion) {
      const mainFile = publication?.redacted_file ? publication?.redacted_file : publication?.file
      _files.push({ id: 'main', file: mainFile, category: file_categories?.find?.(category => category?.refers_to_main_document)?.id })
      if (articleMediaList) {
        articleMediaList?.forEach?.(file => _files.push({ id: file?.id, file: file?.file, category: file?.file_category }))
      }
    } else {
      if (replyMediaList) {
        replyMediaList?.forEach?.(file => {
          _files.push({ id: file?.id, file: file?.file, category: file?.file_category })

        })
      }
    }
    setInitialFiles(_files)
    setUploadedFiles(_files)

  }, [articleMediaList, replyMediaList, isFirstPublicationVersion])

  onUpdate(() => {
    setUpdatedAmount(amountInitialState)
  }, [amountInitialState])

  onUpdate(() => {
    setCanUploadFile(publication?.requires_blindfolded_upload)
  }, [publication?.requires_blindfolded_upload])

  const toggle = () => {
    setUploadedFiles(initialFiles)
    setUpdatedAmount(amountInitialState)
    AppStatus.setModal(['blindfoldedJournalFlow', !visible])
  }

  const onAfterMalwareCheck = async () => {
    AppStatus.set('loading')
    const files = { article: publication?.id }
    uploadedFiles?.forEach?.((file, index) => {
      const mediaID = initialFiles[index]?.id
      files[mediaID] = file
    })
    try {
      AppStatus.setModal(['uploadFiles', false])
      const postedNewFiles = await APIClient.Publications.PostBlindfoldedFiles({ files })
      if (postedNewFiles) {
        toggle()
        setCanUploadFile(false)
        data?.onSuccess?.()
        AppStatus.set('done')
      }
    } catch (e) {
      AppStatus.set('idle')
      OSAlert.error({
        title: 'Error',
        body: 'Error while performing blinded review files operation. Please try again later',
      })
    }

  }

  const onSubmitNewFiles = () => {
    AppStatus.setModal(['blindfoldedJournalFlow', false])
    AppStatus.setModal(['uploadFiles', true, {
      visible: true,
      onSubmit: onAfterMalwareCheck,
      showCategory: false,
      onSendError: () => { },
      publication: publication?.id,
      showConfirmationStep: false,
      initialStep: 'malware_verification',
      files: uploadedFiles,
      file_categories: [],
      onClose: () => setUploadedFiles([]),
    }])
  }

  const onUploadFile = async ({ file: _file, index }) => {
    if (_file?.id === 'main') setValidateMainManuscript(true)
    setTimeout(async () => {
      const file = await fileInputRef.current.openFilePicker()
      setValidateMainManuscript(false)
      if (file) {
        const newFiles = [...uploadedFiles]
        delete newFiles[index]
        newFiles[index] = file[0]?.file
        setUploadedFiles(newFiles)
        const isFileAlreadyUpdated = uploadedFiles[index] instanceof File
        if (!hasUploadedAllFiles && !isFileAlreadyUpdated) {
          setUpdatedAmount(state => state + 1)
        }
      }
    })
  }

  const onRemoveItem = ({ file, index }) => {
    const newItems = [...uploadedFiles]
    const indexToBeDeleted = newItems.findIndex((item) => item === file)
    delete newItems[indexToBeDeleted]
    newItems[indexToBeDeleted] = initialFiles[index]
    setUploadedFiles(newItems.filter(Boolean))
    setUpdatedAmount(state => state - 1)
  }

  const FileItem = ({ file, index }) => {

    const updatedFile = uploadedFiles[index]
    const hasUpdatedFile = updatedFile instanceof File

    const fileToDownload = hasUpdatedFile ? initialFiles[index]?.file : file?.file

    const name = formatFileName(hasUpdatedFile ? updatedFile?.name : file?.file)

    const iconColorVariant = hasUpdatedFile ? 'positive2' : 'neutral5'
    const itemWrapperColorVariant = hasUpdatedFile ? 'bg:neutral2' : 'bg:neutral1'

    const currentFileCategory = file_categories?.find?.(category => category?.id === file?.category)

    const shouldShowFileCategoryTitle = TypeGuards.isString(currentFileCategory?.title)
    const checkFileSize = useMemo(() => fileSizeValidator(file), [file?.size])

    return (
      <>
        <View variants={['fullWidth', 'column']}>
          <View variants={['gap:1']} responsiveVariants={{ mid: ['row'] }}>

            <View variants={['height:default', 'alignCenter']}>
              <Icon
                name='file'
                variants={['small', iconColorVariant as any]}
                debugName={'Blindfolded Journal Flow modal item - File Icon'}
              />
            </View>

            <View variants={['justifySpaceBetween', 'blindfoldedModalItemWrapper', itemWrapperColorVariant as any]}>
              <View variants={['column', 'justifyCenter']}>
                <Text variants={[`p1`]} style={styles.fileItemName} text={name} />
                {shouldShowFileCategoryTitle ? <Text variants={[`p3`, 'color:neutral7']} text={currentFileCategory?.title} /> : null}
                {checkFileSize?.message ? <Text variants={[`p3`, 'color:destructive2']} text={checkFileSize.message} /> : null}
              </View>
              {hasUpdatedFile ? (
                <ActionIcon
                  debugName={`Blindfolded journal flow - on remove item ${updatedFile}`}
                  name='x'
                  variants={['minimal']}
                  onPress={() => onRemoveItem({ file, index })}
                />
              ) : null}
            </View>

            <Button
              variants={[`blindfoldedUploadFileButton`]}
              text={`Upload`}
              icon={'upload'}
              disabled={!canUploadFile}
              debugName={`Blindfolded journal flow - On open OS file picker`}
              onPress={() => onUploadFile({ file, index })}
            />

          </View>

          <View>
            <Text
              variants={[`p2`, 'marginBottom:2', 'link', 'ellipsis']}
              text={`Download file`}
              onPress={() => downloadFileFromURL(fileToDownload, 'download', true)}
              style={styles.link}
            />
          </View>

        </View>

      </>
    )
  }

  return (
    <Modal
      toggle={toggle}
      visible={visible}
      showClose={false}
      dismissOnBackdrop={true}
      variants={['blindfoldedReview', 'centered']}
    >
      <View variants={['fullWidth', 'justifySpaceBetween', 'alignCenter']}>
        <Text variants={[`h3`, 'marginBottom:2']} text={`Upload required files`} />
        <ActionIcon
          icon={'x'}
          variants={['minimal']}
          onPress={toggle}
          debugName={'Blindfolded Journal Flow - close modal'}
        />
      </View>

      <BlindfoldedToaster showRightContent={false} />

      {isLoadingPublication || isLoadingMedia ? (
        <View variants={['fullWidth', 'center']} style={styles.loadingWrapper}>
          <ActivityIndicator debugName={'Activity indicator - loading publication articleMediaList'} />
        </View>
      ) : (
        <>
          <View variants={['center', 'marginTop:4', 'marginBottom:2', 'fullWidth', 'column']}>
            <View variants={['alignCenter', 'marginBottom:1.25', 'gap:1']}>
              {hasUploadedAllFiles ? (
                <Icon
                  name={'check-circle-2'}
                  variants={['positive2', 'small']}
                  debugName={'Blindfolded Journal Flow - File upload completion icon indicator'}
                />
              ) : null}
              <Text
                variants={[`h3`, 'color:neutral8']}
                text={`${updatedAmount}/${initialFiles?.length} Edited files uploaded`}
              />
            </View>
            <Text
              variants={[`p3`, 'color:neutral7']}
              text={`Download the original files and upload them with the author's names edited out.`}
            />
          </View>

          <View style={styles.listWrapper}>
            {uploadedFiles.map((file, index) => <FileItem file={file} index={index} />)}
          </View>
        </>
      )}

      <View variants={['fullWidth', 'center', 'marginTop:4']}>
        <Button
          variants={[`blindfoldedSubmitButton`]}
          text={`Continue to security verification`}
          debugName={`Blindfolded journal flow - on submit`}
          disabled={submitButtonDisabled}
          onPress={onSubmitNewFiles}
        />
      </View>

      <FileInput ref={fileInputRef} id={'BlindfoldedFileInput'} accept={validateMainManuscript ? '.doc,.docx,.pdf' : null} />

    </Modal>
  )
}

const styles = {
  listWrapper: {
    maxHeight: Theme.values.itemHeight.default * 6.5,
    ...Theme.spacing.paddingTop(1),
    ...Theme.presets.column,
    ...Theme.presets.fullWidth,
    overflowY: 'auto',
  },
  link: {
    cursor: 'pointer',
  },
  loadingWrapper: {
    minHeight: 200,
  },
  fileItemName: {
    display: 'block', // Fix for not working -webkit-box
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'unset',
    minHeight: 23,
    wordBreak: 'break-all',

    [Theme.media.down('small')]: {
      display: 'block', // Fix for not working -webkit-box
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      whiteSpace: 'unset',
    },
  },
}
