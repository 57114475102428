import { APIClient } from '@/services'
import { Publication } from '@/types'

type FileCategoryHookOptions = {
  version: Publication['version']
  status: Publication['status']
  show_on_revision?: boolean
  show_on_submission?: boolean
}

export const useFileCategoryOptions = (props: FileCategoryHookOptions) => {

  const {
    version,
    status,
  } = props

  const isFirstPublicationVersion = version === 1
  const isSubmission = (isFirstPublicationVersion || !version) && status !== 'revision_requested'

  const show_on_revision = props?.show_on_revision || !isSubmission
  const show_on_submission = props?.show_on_submission || isSubmission

  const file_categories = APIClient.QueryKeys.fileCategory.getData()

  if (show_on_revision) {
    return file_categories?.show_on_revision
  } else if (show_on_submission) {
    return file_categories?.show_on_submission
  } else {
    return file_categories
  }

}
