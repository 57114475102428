/* eslint-disable max-len */
/* eslint-disable max-lines */
import {
  Text,
  View,
  Touchable,
  Modal,
  TextEditor,
  ToolBar,
  TextEditorExtensions,
  Button,
  ActionIcon,
  AttachmentsDropzone,
  ActivityIndicator,
  JournalRecommendationInput,
  ExpandableView,
  Avatar,
  StatusTag,
} from '@/components'
import { APIClient } from '@/services'
import { AppForms, OSAlert, React, Theme, variantProvider } from '@/app'
import { TypeGuards, onUpdate, useBooleanToggle, useCallback, useEffect, useForm, useMemo, useState } from '@codeleap/common'
import { useEditor } from '@tiptap/react'
import { EmptyPlaceholder, ModalProps } from '@codeleap/web'
import { AppStatus, useAppSelector } from '@/redux'
import { useIsTablet } from '@/utils'
import { useQueryClient } from '@tanstack/react-query'
import Placeholder from '@tiptap/extension-placeholder'

type FormProps = {
  journal: number
  review: number
  article: number
  journalName: string
  articleName: string
  submittedReview: boolean
  setSubmittedReview: React.Dispatch<React.SetStateAction<boolean>>
  draftedReview: boolean
  setDraftedReview: React.Dispatch<React.SetStateAction<boolean>>
  deletedDraft: boolean
  setDeletedDraft: React.Dispatch<React.SetStateAction<boolean>>
  isPreview?: boolean
} & Omit<ModalProps, 'debugName'>

type OnChoicePressItemProps = {
  item: any
  id: number
  value?: number
}

const onConfirmSubmit = ({ onSubmit }: { onSubmit: () => void }) => {
  OSAlert.ask({
    title: 'Are you sure you want to submit?',
    body: `After submitting the review can't be edited afterwards.`,
    options: [
      { text: 'Cancel', onPress: () => null, variants: ['flat', 'large'] },
      { text: 'Submit', onPress: onSubmit, variants: ['large'] },
    ],
  })
}

export const ReviewerQuestionsForm = (props: FormProps) => {
  const {
    journal: journalID,
    review: reviewID,
    article: articleID,
    journalName = 'Journal name',
    articleName = 'Article name',
    isPreview = false,
    ...modalProps
  } = props

  const { isEditor, isPublisher, isAuthor, isReviewer } = APIClient.Session.useSession()
  const reviewQueryKey = [`review-${reviewID}-${articleID}`]
  const queryClient = useQueryClient()

  const onRefetchQuery = () => {
    queryClient.refetchQueries({ queryKey: reviewQueryKey })
  }

  const { data: review } = APIClient.Review.useReview({ id: reviewID, article: articleID })

  const [internalVisible, internalToggle] = useBooleanToggle(false)
  const visible = modalProps?.hasOwnProperty('visible') ? modalProps?.visible : internalVisible
  const toggle = modalProps?.hasOwnProperty('toggle') ? modalProps?.toggle : internalToggle

  const questions = APIClient.Publishers.publisherQuestionsManager.use({
    filter: {
      journal: journalID,
    },
    listOptions: {
      queryOptions: {
        enabled: visible,
      },
    },
  })

  const { data: publication } = APIClient.Publications.publicationsManager.useRetrieve({ id: articleID, ignore_badges: true })

  const form = useForm(AppForms.reviewJournalQuestions, {})
  const shouldDisableForm = isAuthor || (isEditor || isPublisher)

  const editor = useEditor({
    content: '',
    extensions: [...TextEditorExtensions, Placeholder.configure({ placeholder: 'Enter your comment here...', emptyEditorClass: 'placeholder' })],
    editorProps: {
      transformPastedHTML: (html) => html.replace(/<img.*?>/g, ''),
    },
    editable: !shouldDisableForm,
  })

  const text = editor?.getText?.()

  const createReview = APIClient.Review.reviewManager.useCreate()
  const updateReview = APIClient.Review.reviewManager.useUpdate()

  const isTablet = useIsTablet()

  const isReviewSubmitted = !!review && review?.status === 'submitted' && publication?.version === review?.version
  const isReviewDrafted = !!review

  const [articleReviewID, setArticleReviewID] = useState(review?.id)

  const [answers, setAnswers] = useState([])
  const [attachments, setAttachments] = useState([])
  const [tableAttachments, setTableAttachments] = useState([])
  const [mediaToBeDeleted, setMediaToBeDeleted] = useState([])

  const [internalSubmittedReview, setInternalSubmittedReview] = useState(isReviewSubmitted)
  const [internalDraftedReview, setInternalDraftedReview] = useState(isReviewDrafted)
  const [internalDeletedDraft, setInternalDeletedDraft] = useState(false)

  const shouldSubmitReviewAgain = review?.version !== publication?.version

  const draftedReview = modalProps?.hasOwnProperty('draftedReview') ? modalProps?.draftedReview : internalDraftedReview
  const setDraftedReview = modalProps?.hasOwnProperty('setDraftedReview') ? modalProps?.setDraftedReview : setInternalDraftedReview

  const submittedReview = props?.hasOwnProperty('submittedReview') ? props?.submittedReview : internalSubmittedReview
  const setSubmittedReview = props?.hasOwnProperty('setSubmittedReview') ? props?.setSubmittedReview : setInternalSubmittedReview

  const deletedDraft = props?.hasOwnProperty('deletedDraft') ? modalProps?.deletedDraft : internalDeletedDraft
  const setDeletedDraft = props?.hasOwnProperty('deletedDraft') ? modalProps?.setDeletedDraft : setInternalDeletedDraft

  const allQuestionsAnswered = answers?.filter(Boolean).length === questions?.items?.length

  const shouldDisableActions = [
    publication?.status === 'review_submitted',
    (publication?.status === 'under_review' || publication?.status === 'pending_review') && submittedReview,
  ].some(x => x)

  const filteredQuestions = useMemo(() => {
    return answers?.reduce((acc, currValue) => {
      if (currValue?.question?.type === 'number') {
        return { ...acc, numbers: [...acc?.numbers, currValue] }
      } else if (currValue?.question?.type === 'multiple-choice') {
        return { ...acc, multipleChoices: [...acc?.multipleChoices, currValue] }
      }
    }, { multipleChoices: [], numbers: [] })
  }, [answers])

  onUpdate(() => {
    if (visible) {
      onRefetchQuery()
    }
  }, [visible])

  useEffect(() => {
    if (review && isPreview) setAnswers(review?.form_answers)
    if (review && !shouldSubmitReviewAgain) {
      setTableAttachments(review?.media)
      setSubmittedReview(isReviewSubmitted)
      setDraftedReview(isReviewDrafted && !isReviewSubmitted)
      setArticleReviewID(review?.id)
      editor?.setOptions({ editable: !isReviewSubmitted })
    }
  }, [review, isReviewDrafted, isReviewSubmitted, shouldSubmitReviewAgain])

  onUpdate(() => {
    if (editor && review?.comment) {
      editor?.commands?.setContent?.(review?.comment)
    }
  }, [editor, review?.comment])

  const onClearDraft = () => {
    setAnswers([])
    setAttachments([])
    editor?.commands?.setContent?.('')
    toggle()
    setArticleReviewID(null)
  }

  const onSubmitReview = async ({ status }) => {

    AppStatus.set('loading')

    try {
      const shouldSubmitAnswers = !!answers?.length && JSON.stringify(answers) !== JSON.stringify(review?.form_answers)
      const shouldSubmitAttachments = !!attachments?.length

      const currentComment = editor?.getText() ? editor?.getHTML?.() : null

      let newAnswers = []
      let media = null
      let currentReview = review

      const shouldUpdateArticleReview = articleReviewID
      const shouldCreateArticleReview = !articleReviewID

      if (review?.id) {
        answers.forEach(answer => {
          const answerAlreadyStoredInAPI = answer?.id && (answer?.value || answer?.choice)
          if (answerAlreadyStoredInAPI) {
            newAnswers.push({
              question: answer?.question?.id,
              value: answer?.choice ? String(answer?.choice) : String(answer?.value),
            })
          } else {
            newAnswers.push(answer)
          }
        })
      } else {
        newAnswers = answers
      }

      if (shouldCreateArticleReview) {
        currentReview = await createReview.create({
          article: articleID,
          comment: currentComment,
          status,
        })
      }

      if (shouldUpdateArticleReview) {
        currentReview = await updateReview.update({
          id: articleReviewID,
          article: articleID,
          comment: currentComment,
          status,
        })
      }

      if (mediaToBeDeleted) {
        APIClient.Review.onDeleteMediaAttachment({ media: mediaToBeDeleted })
      }

      if (currentReview) {
        if (shouldSubmitAnswers) {
          APIClient.Review.JournalAnswers({
            review: currentReview?.id,
            answers: [
              ...newAnswers,
            ].filter(Boolean),
          })
        }
        if (shouldSubmitAttachments) {
          media = await APIClient.Review.postArticleReviewMedia({
            files: attachments,
            review: currentReview?.id,
          })
        }
      }

      if (status === 'draft') {
        setDraftedReview(true)
      } else {
        setSubmittedReview(true)
        setDraftedReview(false)
        editor?.setOptions({ editable: false })
      }

      setArticleReviewID(currentReview?.id)
      setDeletedDraft(false)
      setAttachments(null)
      setTableAttachments(media)
      setMediaToBeDeleted(null)
    } catch (e) {

    } finally {
      toggle()
      AppStatus.set('done')
    }

  }

  const onDeletedDraft = async () => {
    const response = await APIClient.Review.deleteReviewDraft({ id: articleReviewID, article: articleID })
    if (response) {
      if (TypeGuards.isFunction(setDeletedDraft)) {
        setDeletedDraft(true)
      }
      onClearDraft()
      setSubmittedReview(false)
      setDraftedReview(false)
      setTableAttachments(null)
      setArticleReviewID(null)
      setMediaToBeDeleted(null)
      editor?.setOptions({ editable: true })
    }
  }

  const onSaveDraft = () => {
    onSubmitReview({ status: 'draft' })
    setDraftedReview(true)
  }

  const onRemoveAttachment = (media) => {
    setMediaToBeDeleted(state => {
      if (state) {
        return ([...state, media?.id])
      } else {
        return [media?.id]
      }
    })
  }

  const getRecommendationLabel = (answer, choice) => {
    return answer?.question?.choices?.find(e => e.id === choice)?.label
  }

  const renderItem = useCallback(({ data, index }) => {

    const { question, choices, type, id } = data

    const isRecommendationQuestion = type === 'multiple-choice'

    const onPressItem = (props: OnChoicePressItemProps) => {

      const { id, item, value: _value } = props

      setAnswers(state => {

        const value = !!_value ? _value : item?.value

        const items = [...state]

        const selectedIndex = state?.findIndex?.(choice => choice?.value === String(value) && id === choice?.question)

        const isItemAlreadySelected = selectedIndex !== -1

        if (isItemAlreadySelected) {
          delete items[selectedIndex]
        } else {
          items[index] = {
            question: id,
            value: TypeGuards.isNull(value) ? null : String(value),
          }
        }
        return items
      })
    }

    const renderNumberChoice = (item) => {
      // const isItemSelected = answers && (answers[index] ? (item?.value === null ? true : Number(item?.value) === Number(answers[index]?.value)) : null)
      const isItemSelected = answers && (answers[index] ? String(item?.value) === String(answers[index]?.value) : null)

      const bgColor = isAuthor || (isEditor || isPublisher) ?
        `backgroundColor:${isItemSelected ? 'neutral10' : 'neutral1'}`
        : `backgroundColor:${isItemSelected ? 'neutral10' : 'neutral2'}`
      const textColor = `color:${isItemSelected ? 'neutral1' : 'neutral10'}`

      const ITEM_SIZE = isTablet ? 35 : 47

      return (
        <Touchable
          variants={[bgColor as any, 'padding:2', 'marginRight:1', 'border-radius:tiny', 'marginBottom:1']}
          debugName={`Number ${item}`}
          style={{ width: ITEM_SIZE, height: ITEM_SIZE, cursor: (shouldDisableActions || shouldDisableForm) && 'not-allowed' }}
          onPress={() => onPressItem({ item, id })}
          disabled={shouldDisableActions || shouldDisableForm}
          key={item?.value}
        >
          <Text variants={[`p2`, textColor as any]} text={item?.label === 'N/A' ? item?.label : item?.value} />
        </Touchable>
      )
    }

    const renderRecommendationChoice = (item) => {

      const onPressCheckbox = () => {
        onPressItem({ item, id, value: item?.id })
      }

      let isItemSelected = null

      if (answers) {
        if (TypeGuards.isNumber(answers[answers?.length - 1]?.choice) && !answers[answers?.length - 1]?.value) {
          isItemSelected = item?.id === answers[answers?.length - 1]?.choice
        } else {
          isItemSelected = String(item?.id) === answers[answers?.length - 1]?.value
        }
      }

      if (!item?.id) return null

      return (
        <JournalRecommendationInput
          value={isItemSelected}
          onPress={onPressCheckbox}
          label={item?.label}
          disabled={shouldDisableActions}
          key={item?.id}
        />
      )
    }

    if (isRecommendationQuestion && !isAuthor) {
      return (
        <View variants={['column', 'marginTop:1']}>
          <Text variants={[`p2`, 'color:neutral7', 'semiBold', 'marginBottom:1']} text={`Recommendation`} />
          {choices.map((item) => renderRecommendationChoice(item))}
        </View>
      )
    }

    if (isRecommendationQuestion && isAuthor) {
      return
    }

    // Use this when we need the html to render - remove it later
    const html = `<span style="color: #666; font-weight: bold; font-size: 16px">${index + 1}. &nbsp;</span> ${question}`
    const label = <Text variants={[`p2`, 'semiBold', 'color:neutral7', 'marginBottom:1']} dangerouslySetInnerHTML={{ __html: html }} />

    const variants = isAuthor || (isEditor || isPublisher) ? ['backgroundColor:neutral2', 'padding:2', 'border-radius:smallish'] : ['']

    return (
      <View variants={['column', 'gap:2', ...variants]}>
        {label}
        <View style={styles.numberChoicesWrapper}>
          {choices.map((item) => renderNumberChoice(item))}
        </View>
      </View>
    )
  }, [answers, isTablet])

  const renderContent = () => {

    const isLoadingQuery = questions?.list?.query?.isLoading
    const hasQuestions = !!questions?.items?.length

    if (isLoadingQuery) {
      return (
        <View variants={['flex', 'center']} style={styles.loadingWrapper}>
          <ActivityIndicator debugName='Activity Indicator - Questions Loading' />
        </View>
      )
    }

    if (!isLoadingQuery && !hasQuestions) {
      return (
        <EmptyPlaceholder
          title={'This review does not require any question.'}
          variants={['table']}
          icon={'package-open'}
        />
      )
    }

    const questionsWithNullValues = questions?.items?.reverse?.()?.map((question) => {
      if (question?.type === 'number') {
        return {
          ...question,
          choices: [
            ...question?.choices,
            {
              created_datetime: new Date().toISOString(),
              id: null,
              label: 'N/A',
              question: question?.id,
              value: null,
            },
          ],
        }
      }

      return question
    })

    return (
      <View variants={['column', 'gap:2']}>
        {questionsWithNullValues?.map?.((item, index) => item?.type !== 'multiple-choice' || isReviewer ? renderItem({ data: item, index }) : null)}
      </View>
    )

  }

  return (
    <View variants={['column', 'flex']}>

      <Modal
        debugName={'Profile reauthentication modal'}
        showClose={false}
        withScrollContainer={false}
        toggle={toggle}
        closable={true}
        visible={visible}
        variants={['submitJournalReviewModal', 'centered']}
        styles={{
          body: styles.body,
        }}
      >
        <View variants={['paddingHorizontal:4', 'paddingTop:2', 'paddingBottom:4', 'column', 'fullHeight', 'fullWidth', 'scrollY']}>

          <View variants={['flex', 'fullWidth', 'alignCenter', 'justifySpaceBetween']}>
            <Text variants={['h3', 'color:neutral9']} text={isReviewer && isReviewSubmitted ? `Submit review` : 'Review'} />
            <ActionIcon
              icon={'x'}
              onPress={toggle}
              debugName='Review Questions Modal - Close modal icon'
              variants={['transparent', 'neutral10']}
            />
          </View>

          <View style={styles.scroll} variants={['fullWidth', 'gap:5']}>
            {!isAuthor && (
              <View variants={['column', 'gap:2']}>
                <View variants={['alignCenter', 'gap:1']} style={styles.infosWrapper}>
                  <Text variants={[`h5`, 'color:neutral7', 'semiBold']} text={`Currently reviewing:`} />
                  <Text variants={[`h5`, 'color:neutral10', 'semiBold']} text={articleName} />
                </View>

                <View variants={['alignCenter', 'gap:1']} style={styles.infosWrapper}>
                  <Text variants={[`h5`, 'color:neutral7', 'semiBold']} text={`Article type:`} />
                  <Text variants={[`h5`, 'color:neutral10', 'semiBold']} text={publication?.article_type?.label} />
                </View>

                <View variants={['alignCenter', 'gap:1']} style={styles.infosWrapper}>
                  <Text variants={[`h5`, 'color:neutral7', 'semiBold']} text={`Journal:`} />
                  <Text variants={[`h5`, 'color:neutral10', 'semiBold']} text={journalName} />
                </View>

                {((isEditor || isPublisher) && review?.reviewer?.first_name) && <View variants={['alignCenter', 'gap:1']}>
                  <Text variants={[`h5`, 'color:neutral7', 'semiBold']} text={`Reviewer:`} />
                  <Text variants={[`h5`, 'color:neutral10', 'semiBold']} text={review?.reviewer?.first_name + ' ' + review?.reviewer?.last_name} />
                </View>}

              </View>
            )}

            {isAuthor || (isEditor || isPublisher) ? filteredQuestions?.multipleChoices?.map((answer, index) => {
              const answerLabel = TypeGuards.isNumber(answer?.choice) ? getRecommendationLabel(answer, answer?.choice) : ''
              return (
                <View key={index} variants={['backgroundColor:neutral2', 'border-radius:smallish', 'padding:2', 'column', 'gap:2']}>
                  <Text dangerouslySetInnerHTML={{ __html: answer?.question?.question }} />
                  <View variants={['column', 'gap:1']}>
                    {answerLabel ? <StatusTag label={answerLabel} /> : null}
                  </View>
                </View>
              )
            }) : null}

            <ExpandableView initialState={true} title='Submission form'>
              <>
                {isReviewer && <Text variants={[`h5`, 'semiBold', 'color:neutral8', 'marginBottom:2']} text={`Form section name`} />}
                {renderContent()}
              </>
            </ExpandableView>

            <ExpandableView initialState title={isAuthor || (isEditor || isPublisher) ? 'Reviewer comment' : 'Comment'}>
              {isReviewer && !isReviewSubmitted ? (
                <TextEditor
                  {...form.register('comments')}
                  editor={editor}
                  toolbarComponent={<ToolBar editor={editor} excludeIds={['image', 'fileComponent']} hide={submittedReview} />}
                  variants={['reviewerQuestionsForm', 'marginBottom:5', submittedReview ? 'flat' : '']}
                />
              ) : null}
              {shouldDisableForm && text ? (
                <View variants={['column', 'gap:1', 'border-radius:smallish', 'backgroundColor:neutral2']}>
                  <TextEditor
                    {...form.register('comments')}
                    editor={editor}
                    toolbarComponent={null}
                    variants={['flat', 'flat:disabled', 'disabledPlain']}
                  />
                </View>
              ) : null}
              {isReviewSubmitted && !text ? <EmptyPlaceholder icon='file-text' variants={['table']} title='No comment provided' /> : null}
            </ExpandableView>

            <ExpandableView title='Attachments' initialState styles={{ wrapper: styles.attachmentsWrapper }}>
              {tableAttachments?.length || !isReviewSubmitted ? (
                <AttachmentsDropzone
                  currentAttachments={attachments}
                  setCurrentAttachments={setAttachments}
                  showTable={!!tableAttachments?.length}
                  tableAttachments={tableAttachments}
                  setTableAttachments={setTableAttachments}
                  showDelete={!submittedReview}
                  showDownload
                  showDropzone={isReviewer && !isReviewSubmitted}
                  onDelete={onRemoveAttachment}
                  addMoreButtonProps={{
                    disabled: submittedReview,
                  }}
                  publication={publication?.id}
                  enableCategorySelector={false}
                  dropzoneProps={{
                    noClick: submittedReview,
                    variants: ['ellipsis'],
                  }}
                />

              ) : (
                <EmptyPlaceholder icon='file' variants={['table']} title='No Attachment provided.' />
              )}
            </ExpandableView>

          </View>

          {!submittedReview ? (
            <View variants={['fullWidth', 'marginTop:3', 'justifySpaceBetween']} responsiveVariants={{ small: ['column'] }}>

              <Button
                variants={[`backgroundColor:neutral2`, 'padding:1.5', 'large']}
                responsiveVariants={{ small: ['small'] }}
                icon={'trash'}
                text={`Delete`}
                disabled={!draftedReview || shouldDisableActions}
                debugName={`Review Questions Modal - Delete Review`}
                styles={{ text: styles.deleteButtonText, icon: styles.deleteButtonIcon }}
                onPress={() => AppStatus.setModal(['deleteJournalReviewDraft', true, { onDelete: onDeletedDraft }])}
              />

              <View variants={['gap:2']} responsiveVariants={{ small: ['column', 'marginTop:2'] }}>
                <Button
                  variants={[`backgroundColor:neutral2`, 'padding:1.5', 'large']}
                  responsiveVariants={{ small: ['small'] }}
                  text={`Save draft`}
                  debugName={`Review Questions Modal - Save Draft`}
                  styles={{ text: styles.saveDraftButtonText }}
                  disabled={!allQuestionsAnswered || shouldDisableActions}
                  onPress={onSaveDraft}
                />
                <Button
                  variants={['padding:1.5', 'large']}
                  responsiveVariants={{ small: ['small'] }}
                  text={`Submit review`}
                  disabled={!allQuestionsAnswered || shouldDisableActions}
                  debugName={`Review Questions Modal - Submit Review`}
                  onPress={() => onConfirmSubmit({ onSubmit: () => onSubmitReview({ status: 'submitted' }) })}
                />
              </View>
            </View>
          ) : null}
        </View>
      </Modal>

    </View>
  )
}

export const ReviewModal = () => {

  const { reviewID, publicationID, visible, isPreview } = useAppSelector((state) => state.AppStatus.modals.reviewerQuestionsForm)

  const toggle = () => {
    AppStatus.setModal(['reviewerQuestionsForm', false, {
      reviewID: null,
    }])
  }

  const publication = APIClient.Publications.publicationsManager.useRetrieve({ id: publicationID, ignore_badges: true })

  return <ReviewerQuestionsForm
    review={reviewID}
    visible={visible}
    toggle={toggle}
    journal={publication.data?.journal?.id}
    journalName={publication.data?.journal?.title}
    article={publicationID}
    articleName={publication.data?.title}
    submittedReview
    setSubmittedReview={() => null}
    isPreview={isPreview}
  />
}

const styles = variantProvider.createComponentStyle((theme) => ({
  scroll: {
    ...theme.spacing.paddingBottom(0),
    ...theme.spacing.paddingRight(1),
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  infosWrapper: {
    flexWrap: 'wrap',
    wordBreak: 'break-all',
  },
  loadingWrapper: {
    minHeight: 200,
  },
  numberChoicesWrapper: {
    flexWrap: 'wrap',
  },
  deleteButtonText: {
    color: theme.colors.destructive2,
    ...theme.spacing.marginLeft(1),
  },
  deleteButtonIcon: {
    color: theme.colors.destructive2,
  },
  saveDraftButtonText: {
    color: theme.colors.neutral10,
  },
  body: {
    minWidth: 1000,
    ...theme.presets.fullWidth,

    [theme.media.up('small')]: {
      minWidth: 820,
    },

    [theme.media.down('largeish')]: {
      minWidth: '100%',
    },
  },
  attachmentsWrapper: {
    ...Theme.spacing.paddingBottom(2),
  },

}), true)
