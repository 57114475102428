import React, { useMemo } from 'react'
import { Icon, ReviewerMetrics, Select, Tag, Text, Touchable, View } from '@/components'
import { variantProvider } from '@/app'
import { TypeGuards } from '@codeleap/common'
import { useGetStyles } from '@/utils'
import { AppStatus } from '@/redux'

export function ReviewHeader({ publication, version, setVersion, showVersionControl, toggle }) {
  const { getStyles } = useGetStyles(styles)

  const fields = useMemo(() => {
    return [
      { title: 'Currently reviewing', field: () => {
        return <>
          {!showVersionControl && <Tag variants={['version']} text={`Version ${version}`} debugName='manuscript version tag'/>}
          <Text variants={[`p1`]} text={publication?.title}/>
        </>
      } },
      { title: 'Journal', field: publication?.journal?.title },
      { title: 'Author', field: publication?.author },
      showVersionControl && {
        title: 'Manuscript version',
        field: () => {
          const options = Array.from({ length: publication?.version || 0 }).map((_, i) => ({
            value: i + 1,
            label: `${i + 1}`,
          }))
          return (
            <Select
              options={options}
              value={version}
              placeholder={version}
              onValueChange={setVersion}
              debugName='select version'
              styles={getStyles('select')}
            />
          )
        },
      },
      showVersionControl && {
        title: 'Review metrics',
        field: () => {
          const onPress = () => {
            toggle()
            setTimeout(() => AppStatus.setModal(['invitesDetails', true, { data: { ...publication, currentVersion: version }}]), 400)
          }

          return (
            <>
              <ReviewerMetrics version={version} article={publication?.id} />
              <Touchable variants={['row', 'gap:0.5', 'marginRight:auto']} onPress={onPress} debugName='view details touchable' >
                <Text variants={['h5']} css={styles.viewDetails} text={'View details'}/>
                <Icon name='external-link' style={styles.icon} debugName='view details icon' />
              </Touchable>
            </>
          )
        },
      },
    ].filter(Boolean)
  }, [publication, version, showVersionControl])

  return (
    <View variants={['column', 'gap:2']}>
      {fields.map(({ title, field }) => (
        <View variants={['column', 'gap:1']}>
          <Text variants={['p2', 'color:neutral7']} text={title} />
          {TypeGuards.isFunction(field) ? field() : <Text variants={['p1', 'color:neutral8']} text={field} />}
        </View>
      ))}
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    selectWrapper: {
      ...theme.spacing.marginRight('50%'),
    },
    selectPlaceholder: {
      color: theme.colors.neutral9,
    },
    selectErrorMessage: {
      display: 'none',
    },

    viewDetails: {
      textDecoration: 'underline',
      color: theme.colors.primary5,
      lineHeight: '18px',
    },
    icon: {
      color: theme.colors.primary5,
      ...theme.sized(2.25),
    },
  }),
  true,
)
