import { React, variantProvider } from '@/app'
import { Attachment, Select, Table, View, StateAction, Text, TableProps, ColumnMap, Publication, useUploadFilesContext } from '@/components'
import { AnyFunction, TypeGuards, onUpdate, usePrevious } from '@codeleap/common'
import {
  ButtonProps,
  DropzoneProps,
  DropzoneRef,
} from '@codeleap/web'
import { Button, Icon, Touchable } from '@/components'
import { forwardRef, useMemo } from 'react'
import { downloadFileFromURL, formatFileName, useFileCategoryOptions } from '@/utils'
import { humanizedFileSize } from '@/utils'
import { FileCategory, Publication as PublicationType, PublicationMedia } from '@/types'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'

type TableColumnMapType = (params: {
  onCategoryChange: AttachmentsDropzoneProps['onCategoryChange']
  onDelete: (media: PublicationMedia) => void
}) => ColumnMap<AttachmentsDropzoneProps['tableAttachments']>

type AttachmentsDropzoneProps = React.PropsWithChildren<{
  currentAttachments?: Attachment[]
  setCurrentAttachments?: StateAction<AttachmentsDropzoneProps['currentAttachments']>
  tableAttachments?: PublicationMedia[]
  setTableAttachments?: StateAction<AttachmentsDropzoneProps['tableAttachments']>
  onCategoryChange?: (newCategory: string, mediaObj: Attachment) => void
  onDelete?: (att: Attachment) => void
  onChangeFile?: (files: File[]) => any
  tableColumnMap?: TableColumnMapType
  showAddMoreBtn?: boolean
  showTable?: boolean
  showDropzone?: boolean
  showFileCategory?: boolean
  showDelete?: boolean
  showDownload?: boolean
  disabled?: boolean
  tableProps?: TableProps
  publication: PublicationType['id']
  dropzoneProps?: Partial<DropzoneProps>
  addMoreButtonProps?: Omit<ButtonProps, 'debugName'>
  addMoreComponent?: (x: { openPicker: () => void }) => JSX.Element
  canChangeCategory?: boolean
  ref?: React.Ref<DropzoneRef>
  showFakeDropzone?: boolean
  showSecondaryAddMoreBtn?: boolean
  enableCategorySelector?: boolean
}>

function _AddMoreBtnComponent(addMoreButtonProps) {
  return (
    <Button
      debugName='Add more button on AttachmentsDropzone'
      text='Add more'
      icon='add-file'
      variants={['gap:1', 'flat', 'marginHorizontal:auto']}
      onClick={(e) => {
        e.stopPropagation()
        addMoreButtonProps?.openPicker?.()
      }}
      {...addMoreButtonProps}
    />
  )
}

function ListIcon({ onPress, iconName, color }) {
  return (
    <Touchable onPress={onPress} debugName={`Touchable ListIcon ${iconName}`}>
      <Icon name={iconName} variants={[color, 'small']} debugName={`Icon ListIcon ${iconName}`} />
    </Touchable>
  )
}

type FakeDropzoneProps = {
  attachments: Attachment[]
  setAttachments: StateAction<AttachmentsDropzoneProps['currentAttachments']>
  dropzoneProps?: Partial<DropzoneProps>
  fileCategories: FileCategory[]
  openModal: AnyFunction
  showAddMoreBtn?: boolean
  AddMoreButton?: () => JSX.Element
  enableCategorySelector?: boolean
  disabled?: boolean
}

const FakeDropzone = (props: FakeDropzoneProps) => {
  const {
    dropzoneProps,
    attachments,
    setAttachments,
    fileCategories,
    openModal,
    showAddMoreBtn,
    AddMoreButton,
    enableCategorySelector = true,
    disabled = false,
  } = props

  const placeholder = dropzoneProps?.placeholder || 'Please choose files for upload. Files up to 256MB.'

  const hasFiles = attachments?.length > 0

  const handleAttachmentRemove = (attachment: Attachment | File) => {
    setAttachments(state => state.filter(f => {
      const isLocal = attachment instanceof File
      if (isLocal) {
        return f.name !== attachment.name
      } else {
        return f.file.name !== attachment.file.name
      }
    }))
  }

  return (
    <Touchable
      debugName='FakeDropZone:Attachments'
      onPress={openModal}
      variants={['fullWidth']}
      style={styles.fakeDropzone}
      disabled={disabled}
    >
      <View variants={['column', 'gap:2', 'center', 'fullWidth']}>
        {!hasFiles ? (
          <View style={styles.iconWrapper}>
            <Icon name='add-file' debugName='FakeDropzone:Icon' />
          </View>
        ) : null}

        {hasFiles ? (
          <View variants={['fullWidth', 'column', 'gap:1']}>
            {attachments.map((attachment, index) => {

              return (
                <Publication.FilePreview
                  file={enableCategorySelector ? attachment.file : attachment}
                  fileCategories={fileCategories}
                  setAttachments={setAttachments}
                  index={index}
                  variants={enableCategorySelector ? ['ellipsis', 'media'] : ['noCategory']}
                  fileRightIcon={'x'}
                  onRemove={() => handleAttachmentRemove(attachment)}
                  category={!!attachment?.file_category ? attachment.file_category : null}
                  fileLeftIcon={enableCategorySelector ? 'grip-vertical' : 'file'}
                />
              )
            })}
          </View>
        ) : null}

        {showAddMoreBtn ? <AddMoreButton /> : null}
        <Text text={placeholder} variants={['p1', 'color:neutral7', 'textCenter']} />
      </View>
    </Touchable>
  )
}

const AttachmentsDropzoneComponent = (props: AttachmentsDropzoneProps, ref) => {

  const {
    currentAttachments,
    setCurrentAttachments,
    tableAttachments,
    setTableAttachments,
    addMoreComponent: AddMoreBtnComponent,
    tableColumnMap,
    onCategoryChange,
    onDelete,
    tableProps,
    dropzoneProps,
    addMoreButtonProps,
    canChangeCategory = true,
    showAddMoreBtn = true,
    showTable = true,
    showDropzone = true,
    showFileCategory = false,
    showDelete = false,
    showDownload = false,
    disabled = false,
    children,
    showSecondaryAddMoreBtn = false,
    publication: publicationID,
    enableCategorySelector = true,
  } = props

  const { data: publication } = APIClient.Publications.publicationsManager.useRetrieve({ id: publicationID })

  const file_categories = useFileCategoryOptions({ version: publication?.version, status: publication?.status })

  const categoriesWithoutMainDocument = useMemo(() => file_categories?.filter(c => !c.refers_to_main_document), [file_categories])

  const prevTableAttachmentsLength = usePrevious(tableAttachments?.length || 0)
  const _ref = ref || React.useRef<DropzoneRef>()
  const { setCurrentModal } = useUploadFilesContext()

  onUpdate(() => {
    if (tableAttachments?.length > prevTableAttachmentsLength || !currentAttachments?.length) {
      _ref?.current?.clear?.()
    }
  }, [tableAttachments?.length, currentAttachments?.length])

  const handleCategoryChange = (newCategory: string, mediaObj: Attachment) => {
    setTableAttachments((prev) => {
      prev[mediaObj?.index] = { ...mediaObj, file_category: Number(newCategory) }
      return prev
    })
    onCategoryChange?.(newCategory, mediaObj)
  }

  function _onDelete(media: PublicationMedia) {
    setTableAttachments((prev: PublicationMedia[]) => {
      return prev?.filter(item => item?.id !== media?.id)
    })

    if (media?.id) onDelete?.(media)
  }

  const onMalwareCheck = (acceptedFiles) => {
    AppStatus.setModal(['uploadFiles', false, null])
    setCurrentAttachments(state => {
      return [
        ...state,
        ...acceptedFiles.map((e) => (enableCategorySelector ? {
          file: e.file, file_category: e.file_category,
        } : e)),
      ]
    })
  }

  const openAttachmentsModal = () => {
    setCurrentModal('attachments')
    AppStatus.setModal(['uploadFiles', true, {
      visible: true,
      onSubmit: onMalwareCheck,
      dropzoneProps,
      showCategory: enableCategorySelector,
      onSendError: () => AppStatus.setModal(['uploadFiles', false, null]),
      publication: publication?.id,
      showConfirmationStep: false,
      file_categories: categoriesWithoutMainDocument,
      initialStep: null,
    }])
  }

  const _columnMap = useMemo(
    () => [
      {
        leftIcon: 'file',
        component: ({ file }) => <Text text={formatFileName(file)} variants={['ellipsis']} responsiveVariants={{ smallish: ['noWrap'] }} />,
        mainColumn: true,
      },
      showFileCategory && {
        component: (media) => {
          const currentCategory = file_categories?.find(cat => cat?.value === media?.file_category)
          return <View variants={['fullWidth']} style={{ minWidth: 200, maxWidth: 200 }}>
            {canChangeCategory ?
              <Select
                loadInitialValue
                options={categoriesWithoutMainDocument}
                value={media?.file_category}
                onValueChange={(category) => handleCategoryChange(category, media)}
                placeholder={'Select a category'}
                defaultValue={''}
                variants={['fullWidth', 'noError', 'filePreview']}
                disabled={disabled}
              /> : <Text variants={[`p1`]} text={currentCategory?.title || ''} />}
          </View>
        },
      },
      {
        text: ({ file_size }) => humanizedFileSize(file_size),
      },
      showDelete && {
        component: (media) => <ListIcon iconName='x' color='destructive2' onPress={() => _onDelete(media)} />,
      },
      showDownload && {
        component: (media) => (
          <ListIcon iconName={'download'} color='primary5:outline' onPress={() => downloadFileFromURL(media.file)} />
        ),
      },
    ],
    [showFileCategory, showDelete, showDownload, tableAttachments],
  )

  const columnMap = useMemo(
    () => TypeGuards.isFunction(tableColumnMap)
      ? tableColumnMap({
        onCategoryChange: handleCategoryChange,
        onDelete: _onDelete,
      })
      : _columnMap.filter(Boolean),
    [tableColumnMap],
  )

  const AddMoreButton = AddMoreBtnComponent || _AddMoreBtnComponent

  const _showAddMoreBtn = showAddMoreBtn && !!currentAttachments?.length

  return (
    <>
      <View variants={['fullWidth', 'column', 'gap:1']}>
        {showDropzone ? (
          <FakeDropzone
            dropzoneProps={dropzoneProps}
            fileCategories={categoriesWithoutMainDocument}
            attachments={currentAttachments}
            setAttachments={setCurrentAttachments}
            openModal={openAttachmentsModal}
            showAddMoreBtn={_showAddMoreBtn}
            AddMoreButton={() => <AddMoreButton openPicker={openAttachmentsModal} {...addMoreButtonProps} />}
            enableCategorySelector={enableCategorySelector}
            disabled={disabled}
          />
        ) : null}
        {showTable ? (
          <Table
            debugName='Attachments list'
            hidePaginationButtons

            showHeader={false}
            columnMap={columnMap as ColumnMap}
            {...tableProps}
            items={tableAttachments || []}
          />
        ) : null}

        {children}
        {showSecondaryAddMoreBtn ? (
          <AddMoreButton openPicker={openAttachmentsModal} {...addMoreButtonProps} />
        ) : null}
      </View>
    </>
  )
}

export const AttachmentsDropzone = forwardRef(AttachmentsDropzoneComponent) as unknown as (
  props: AttachmentsDropzoneProps,
) => JSX.Element

const styles = variantProvider.createComponentStyle((theme) => ({
  fakeDropzone: {
    borderRadius: theme.borderRadius.small,
    ...theme.spacing.paddingHorizontal(2),
    ...theme.spacing.paddingVertical(3),
    ...theme.border.neutral4({ style: 'dashed', width: 1.5 }),
  },
  iconWrapper: {
    width: theme.spacing.value(7),
    height: theme.spacing.value(7),
    backgroundColor: theme.colors.neutral2,
    borderRadius: theme.borderRadius.rounded,
    ...theme.presets.center,
  },
}), true)
