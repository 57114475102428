/* eslint-disable max-len */
import { OSAlert, Theme, variantProvider, React } from '@/app'
import { Modal, Text, View, Avatar, Button, Tag } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { QueryKeys } from '@/services/api'
import { onUpdate, useBooleanToggle, useCallback, useMemo } from '@codeleap/common'
import { useAnimatedStyle, ActivityIndicator } from '@codeleap/web'
import { reviewInvitesManager } from '@/services/api/publication/reviewers/possibleReviewer'

type ReviewerDetailProps = {visible: boolean; data: Partial<{full_name: string; id: number; publication: number; onClose: () => void}>}

const CITATIONS_TO_SHOW = 5

const CitationsWrapper = ({ citations, visible }) => {
  const citationsToShow = citations
  const [showFullCitations, toggleFullCitations] = useBooleanToggle(false)
  const height = (24 + Theme.spacing.value(1)) * CITATIONS_TO_SHOW

  const animated = useAnimatedStyle(() => {
    return {
      height: showFullCitations ? 'auto' : height,
      transition: {
        duration: 0.5,
      },
    }
  }, [showFullCitations])

  onUpdate(() => {
    if (!visible) toggleFullCitations(false)
  }, [visible])

  return (
    <View variants={['column', 'gap:1']}>
      <Text variants={['p2', 'color:neutral7']} text='Citations' />
      <View
        variants={['column', 'gap:1']}
        animated
        animatedProps={animated}
        style={{
          overflow: 'hidden',
        }}
      >
        {citationsToShow.map((citation) => <Text variants={['p1', 'color:neutral8']} text={`${citation.year}: ${citation.citations}`} />)}
      </View>
      <Button
        text={showFullCitations ? 'See less citations' : 'See more citations'}
        variants={['minimal', 'small']}
        debugName='citations:see more'
        onPress={() => toggleFullCitations()}
        debounce={500}
      />
    </View>
  )
}

const _Tag = (props) => {
  return <Tag
    debugName={`${props?.id ?? props?.text}-tag`}
    variants={['bg:neutral2', 'border-radius:nanoish']}
    {...props}
  />
}

export const ReviewerDetails = () => {
  const { visible, data } = useAppSelector(state => state.AppStatus.modals.reviewerDetails as ReviewerDetailProps)

  const { data: reviewerDetails, query } = reviewInvitesManager.useRetrieve({
    id: { id: data?.id, article: data?.publication },
    queryOptions: {
      enabled: visible,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  })
  const metrics = useMemo(() => {
    return {
      full_name: data?.full_name || reviewerDetails?.full_name,
      email: reviewerDetails?.email,
      status: reviewerDetails?.status,
      invites_received: reviewerDetails?.invites_received || '0',
      invites_accepted: reviewerDetails?.invites_accepted || '0',
      completed_reviews: reviewerDetails?.completed_reviews || '0',
      review_average_time: reviewerDetails?.review_average_time || '0',
      articles: reviewerDetails?.articles,
      interests: reviewerDetails?.interests,
      organisation: reviewerDetails?.organisation,
      affiliations: reviewerDetails?.affiliations,
      website: reviewerDetails?.website,
      tags: reviewerDetails?.tags,
      citations: reviewerDetails?.citations,
      previousReviewer: reviewerDetails?.previous_reviewer,
      internal: reviewerDetails?.is_internal,
      suggestion: reviewerDetails?.suggestion,
    }
  }, [reviewerDetails])

  const articles = useMemo(() => {
    if (!metrics?.articles?.length) return ''
    return metrics.articles.slice(0, 3)
  }, [metrics?.articles, visible])

  const interests = useMemo(() => {
    if (!metrics?.interests?.length) return ''
    return metrics.interests.slice(0, 5).map(e => e.title).join(', ')
  }, [metrics?.interests, , visible])

  const statusTags = useMemo(() => {
    return [
      metrics.internal && {
        text: 'eworkflow reviewer',
        leftIcon: 'eworkflow-outline',
      },
      metrics.previousReviewer && {
        text: 'Previous reviewer',
        leftIcon: 'undo-2',
      },
      metrics.suggestion && {
        text: 'Author suggestion',
        leftIcon: 'pen-tool',
      },
    ].filter(Boolean)
  }, [metrics.internal, metrics.suggestion, metrics.previousReviewer])

  const scientificField = useCallback((id) => QueryKeys?.scientificTag?.getData?.()?.find(t => t.id === id)?.label, [QueryKeys?.scientificTag?.getData?.()])

  const toggle = () => {
    AppStatus.setModal(['reviewerDetails', false])
    setTimeout(() => {
      AppStatus.setModal(['reviewerDetails', false, { data: null }])
      data?.onClose?.()
    }, 500)
  }

  const handleDeleteInvite = async () => {
    await AppStatus.set('loading')
    await APIClient.Articles.deleteReviewer({ article: data?.publication, reviewer: data?.id })
    await data?.onDeleteInvite?.()
    toggle()
    AppStatus.set('done')
  }

  return (
    <Modal
      title='Reviewer details'
      toggle={toggle}
      visible={visible}
      styles={{
        box: styles.box,
        body: query?.isLoading ? styles.body : {},
      }}
    >
      {!query?.isLoading ? (
        <>
          <View variants={['gap:3', 'column']}>
            <View variants={['gap:1', 'alignCenter']}>
              <Avatar name={metrics?.full_name || 'Name Unavailable'} debugName={`${metrics?.full_name} Avatar`} variants={['mid']} />

              <View variants={['column']}>
                <Text variants={['h4', 'color:neutral8']} text={metrics?.full_name} />
                <Text variants={['p1', 'color:neutral8']} text={metrics?.organisation || metrics?.affiliations} />
              </View>
            </View>

            <View variants={['column', 'gap:1']}>
              <Text variants={['p2', 'color:neutral7']} text='eWorkflow metrics' />
              <View variants={['backgroundColor:neutral2', 'border-radius:tiny', 'padding:2', 'fullWidth']} style={styles.metricsWrapper}>
                <View variants={['column', 'gap:1']}>
                  <Text variants={['p2', 'color:neutral8']} text={'Invites received'}/>
                  <Text variants={['h5']} text={metrics?.invites_received}/>
                </View>
                <View variants={['column', 'gap:1']}>
                  <Text variants={['p2', 'color:neutral8']} text={'Invites accepted'}/>
                  <Text variants={['h5']} text={metrics?.invites_accepted + '%'}/>
                </View>
                <View variants={['column', 'gap:1']}>
                  <Text variants={['p2', 'color:neutral8']} text={'Completed reviews'}/>
                  <Text variants={['h5']} text={metrics?.completed_reviews + '%'}/>
                </View>
                <View variants={['column', 'gap:1']}>
                  <Text variants={['p2', 'color:neutral8']} text={'Review average time'}/>
                  <Text variants={['h5']} text={metrics?.review_average_time + ' days'}/>
                </View>
              </View>
            </View>

            <View style={styles.grid}>
              <View variants={['column', 'gap:1']}>
                <Text variants={['p2', 'color:neutral7']} text='Email address' />
                <Text variants={['p1', 'color:neutral8', 'breakWord']} text={metrics?.email} />
              </View>

              {reviewerDetails?.status ? (
                <View variants={['column', 'gap:1']}>
                  <Text variants={['p2', 'color:neutral7']} text='Invite status' />
                  <Text variants={['p1', 'color:neutral8', 'breakWord', 'capitalize']} text={reviewerDetails?.status} />
                </View>
              ) : null}
              {reviewerDetails?.website ? (
                <View variants={['column', 'gap:1']}>
                  <Text variants={['p2', 'color:neutral7']} text='Website' />
                  <Text variants={['p1', 'color:neutral8', 'breakWord']} text={metrics?.website} />
                </View>
              ) : null}
            </View>

            {interests ? (
              <View variants={['column', 'gap:1']}>
                <Text variants={['p2', 'color:neutral7']} text='Interests' />
                <Text variants={['p1', 'color:neutral8']} text={interests} />
              </View>
            ) : null}
            {articles ? (
              <View variants={['column', 'gap:1']}>
                <Text variants={['p2', 'color:neutral7']} text='Manuscripts' />
                <View variants={['column', 'gap:0.5']}>
                  {articles.map(article => (
                    <View variants={['row', 'fullWidth', 'gap:0.5']}>
                      <Text variants={['p1', 'color:neutral8']} text='•' />
                      <Text variants={['p1', 'color:neutral8']} text={article.title} />
                    </View>
                  ))}
                </View>
              </View>
            ) : null}
            {metrics?.citations?.length ? (
              <CitationsWrapper citations={metrics?.citations} visible={visible} />
            ) : null}
          </View>
          {statusTags?.length ? (
            <View variants={['column', 'gap:1']}>
              <Text variants={['p2', 'color:neutral7']} text='Tags' />
              <View variants={['gap:1', 'wrap']}>
                {statusTags.map(props => <_Tag {...props} />)}
              </View>
            </View>
          ) : null}
          {metrics?.tags?.length ? (
            <View variants={['column', 'gap:1']}>
              <Text variants={['p2', 'color:neutral7']} text='Scientific fields' />
              <View variants={['gap:1', 'wrap']}>
                {metrics?.tags?.map(field => <_Tag text={scientificField(field)} />)}
              </View>
            </View>
          ) : null}
          {interests ? (
            <View variants={['column', 'gap:1']}>
              <Text variants={['p2', 'color:neutral7']} text='Interests' />
              <Text variants={['p1', 'color:neutral8']} text={interests} />
            </View>
          ) : null}
          {articles ? (
            <View variants={['column', 'gap:1']}>
              <Text variants={['p2', 'color:neutral7']} text='Manuscripts' />
              <View variants={['column', 'gap:0.5']}>
                {articles.map(article => (
                  <View variants={['row', 'fullWidth', 'gap:0.5']}>
                    <Text variants={['p1', 'color:neutral8']} text='•' />
                    <Text variants={['p1', 'color:neutral8']} text={article.title} />
                  </View>
                ))}
              </View>
            </View>
          ) : null}
          {metrics?.citations?.length ? <CitationsWrapper citations={metrics?.citations} visible={visible} /> : null}
          {data?.allowDeleteInvite ? (
            <Button
              variants={['destructive:minimal', 'bg:neutral2', 'marginHorizontal:auto', 'marginTop:2']}
              text='Uninvite'
              icon='send'
              debugName='delete-invite'
              onPress={() => OSAlert.warn({
                title: 'Remove Reviewer Invitation',
                body: "Are you sure you want to remove this invitation?\n\n\nThis action will prevent the reviewer from participating in the current version's review process.",
                onAccept: handleDeleteInvite,
              })}
            />
          ) : null}

        </>
      ) : (
        <View variants={['fullWidth', 'fullHeight', 'center']}>
          <ActivityIndicator variants={['medium', 'alignSelfCenter']} debugName={'Reviewer details ActivityIndicator'} />
        </View>
      )}
    </Modal>
  )
}

const MODAL_WIDTH = 420

const styles = variantProvider.createComponentStyle((theme) => ({
  box: {
    minWidth: MODAL_WIDTH,
    minHeight: MODAL_WIDTH,
  },
  body: {
    minWidth: MODAL_WIDTH,
    minHeight: MODAL_WIDTH,
    ...theme.presets.justifyCenter,
  },
  metricsWrapper: {
    display: 'grid',
    gridTemplateColumns: `1fr 1fr 1fr 1fr`,
    ...theme.spacing.gap(2),
    [theme.media.down('small')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.media.down('tiny')]: {
      gridTemplateColumns: '1fr',
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: `1fr 1fr`,
    ...theme.spacing.paddingBottom(3),
    ...theme.spacing.gap(3),
    ...theme.border.neutral2({ width: 1, directions: ['bottom'] }),
    [theme.media.down('small')]: {
      gridTemplateColumns: '1fr',
    },
  },
}), true)
