import { Publication, Reviewer } from '@/types'
import { PaginationResponse, ReactQuery, onUpdate, useMemo, useState, useUnmount } from '@codeleap/common'
import { api } from '@/app'
import { APIClient } from '@/services'
import { useQueryClient } from '@tanstack/react-query'

export * from './invites'
export * from './possibleReviewer' // fix that shit
export * from './automaticInvite'
export * from './metrics'

const BASE_URL = 'article/possible_reviewer/'
const MAX_REVIEWERS_PAGES = 40
const MAX_REVIEWERS = 50
const REFRESH_DELAY = 10000
const REFRESH_DELAY_WITH_REVIEWERS = 2000

const listItems = async (limit = 10, offset, filter: { article: Publication['id']}) => {
  const response = await api.get<PaginationResponse<Reviewer>>(BASE_URL, { params: { limit, offset, ...filter }})

  return response.data
}

export const useReviewers = (params: { id: number; isNew?:boolean }) => {
  const { id: publicationId, isNew } = params
  const queryClient = useQueryClient()
  const { data: publication } = APIClient.Publications.useRetrieve({ id: publicationId, ignore_badges: true })
  const [reviewers, setReviewers] = useState([])
  const [delay, setDelay] = useState(REFRESH_DELAY)
  const [offset, setOffset] = useState(0)

  useUnmount(() => {
    setReviewers([])
    setDelay(REFRESH_DELAY)
    setOffset(0)
    queryClient.setQueryData(['reviewers', `${publicationId}`], (oldData) => ({
      pageParams: [],
      pages: [[]],
    }))
    queryClient.invalidateQueries(['reviewers', `${publicationId}`])
  })

  const { data, fetchNextPage } = ReactQuery.useInfiniteQuery({
    queryKey: ['reviewers', `${publicationId}`],
    refetchOnWindowFocus: false,
    queryFn: ({ pageParam = 0 }) => {
      setOffset(pageParam)
      return listItems(10, pageParam, { article: publicationId })
    },
  })

  onUpdate(() => {
    if (!isNew) {
      setDelay(REFRESH_DELAY_WITH_REVIEWERS)
    } else {
      setDelay(REFRESH_DELAY)
    }
  }, [isNew])

  onUpdate(() => {
    if (offset < MAX_REVIEWERS_PAGES || reviewers.length < MAX_REVIEWERS) {
      const interval = setInterval(async () => {
        if (data?.pages?.[data?.pages?.length - 1]?.next) {
          await fetchNextPage?.({ pageParam: offset + 10 })
        } else {
          await fetchNextPage?.({ pageParam: offset })
        }
      }, delay)

      return () => clearInterval(interval)
    }
  }, [data?.pages?.length])

  useMemo(() => {
    if (data?.pages?.[data?.pages?.length - 1]?.results) {
      const lastReviewers = data?.pages?.[data?.pages?.length - 1]?.results || []
      const uniqueLastReviewers = lastReviewers.filter(
        obj => !reviewers.find(existingObj => existingObj.id === obj.id),
      )

      const updatedReviewers = [...reviewers, ...uniqueLastReviewers]

      setReviewers(updatedReviewers)
    } else {
      setReviewers([...(reviewers || [])])
    }
  }, [data?.pages?.length, data?.pages?.[data?.pages?.length - 1]?.results?.length])

  return {
    publication,
    reviewers,
    reviewersLoaded: reviewers.length >= MAX_REVIEWERS,
  }
}

