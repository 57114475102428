import React from 'react'
import { useEditor } from '@tiptap/react'
import { TextEditorExtensions, ToolBar, View, usePublicationForm, Text } from '@/components'
import { onUpdate, useRef, useState } from '@codeleap/common'
import { EmptyPlaceholder, FileInputRef, TextEditor } from '@codeleap/web'
import Placeholder from '@tiptap/extension-placeholder'
import { CoverLetterMaxLength, CoverLetterMinLength } from '@/app/forms'

export const CoverLetterForm = () => {
  const { forms, setCoverLetterIsValid, isPublicationEditable } = usePublicationForm()
  const [validate, setValidate] = useState(false)
  const editor = useEditor({
    content: forms.coverLetter?.values.cover_letter,
    extensions: [...TextEditorExtensions, Placeholder.configure({ placeholder: 'Start writing your cover letter here', emptyEditorClass: 'placeholder' })],
    onUpdate: ({ editor }) => {
      forms.coverLetter.setFieldValue('cover_letter', (
        forms.coverLetter.validateAll(true),
        editor.getHTML()
      ))
    },
    onBlur: () => setValidate(true),
    editable: isPublicationEditable,
  })

  const coverLetterFormValue = Object.values(forms.coverLetter.fieldErrors)[0]
  const textEditorRef = useRef<FileInputRef>(null)
  const isMinValid = editor?.getText()?.length >= CoverLetterMinLength
  const isMaxValid = editor?.getText().length <= CoverLetterMaxLength
  const hasError = validate ? (!isMaxValid || !isMinValid) : false

  onUpdate(() => {
    if (forms.coverLetter?.values?.cover_letter !== editor?.getHTML()) {
      editor?.commands?.setContent(forms.coverLetter.values.cover_letter)
    }
    const isValid = isMinValid && isMaxValid
    setCoverLetterIsValid(isValid)
  }, [forms.coverLetter?.values?.cover_letter, !!editor?.getText(), isMinValid, isMaxValid])

  if (!isPublicationEditable && !editor?.getText()) {
    return <EmptyPlaceholder icon='license' variants={['table']} title='No Cover Letter found.' />
  }

  return (
    <View variants={['fullWidth', 'column', 'marginBottom:3']}>
      <TextEditor
        {...forms.coverLetter.register('cover_letter')}
        editor={editor}
        fileInputRef={textEditorRef}
        toolbarComponent={<ToolBar editor={editor} excludeIds={['fileComponent']} hide={!isPublicationEditable}/>}
        variants={['reviewerQuestionsForm', 'disabledPlain', !isPublicationEditable ? 'flat' : '']}
        _error={hasError || coverLetterFormValue.length >= 1}
        validate={false}
      />
      {coverLetterFormValue.length >= 1 ? (
        <Text variants={['p3', 'color:destructive2']} text={coverLetterFormValue} />
      ) : (
        <>
          {validate && !isMinValid && <Text variants={['p3', 'color:destructive2']} text={`Minimum of ${CoverLetterMinLength} characters`} />}
          {validate && !isMaxValid && <Text variants={['p3', 'color:destructive2']} text={`Maximum of ${CoverLetterMaxLength} characters`} />}
        </>
      )}
    </View>
  )
}
