import { ManuscriptStatus } from '@/components'
import { Publication } from '@/types'
import { DateUtils } from '@/utils'
import { View, Text } from '@codeleap/web'

export const StatusColumn = ({ status, version, status_datetime }: Publication) => (
  <View variants={['column', 'gap:0.5', 'center', 'fullWidth']}>
    <Text variants={[`p2`, 'color:neutral8', 'regular']} text={`Version ${version}`} />
    <ManuscriptStatus.Tag status={status} />
    <Text text={DateUtils.formatDate(status_datetime, 'dd/MM/yy')} variants={['p3', 'color:neutral7']} />
  </View>
)
