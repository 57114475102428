import { createSlice, TypeGuards } from '@codeleap/common'
import { api, LocalStorage, Settings } from '@/app'
import { Attachment } from '@/components'

export type AppStatusStates = 'loading' | 'done' | 'idle' | 'splash' | 'blank' | 'sectionBreakdown'

const modals = {
  test: false,
  network: false,
  debug: false,
  auth: false,
  uploadVersionFiles: { visible: false, review: null, isMedia: false },
  uploadPublicationFiles: { visible: false, isMedia: false },
  uploadAttachments: {
    visible: false,
    dropzoneProps: {},
    onContinue: (files: Attachment[]) => null,
    internalAttachments: [],
    setInternalAttachments: (attachments: Attachment[]) => null,
    showCategory: true,
    file_categories: [],
    onSendError: () => null,
  },
  uploadFiles: {
    visible: false,
    onSubmit: (acceptedFiles: File[] | Attachment[]) => null,
    showCategory: true,
    onSendError: () => null,
    onSuccess: () => null,
    showConfirmationStep: true,
    showImageStatement: false,
    dropzoneProps: {},
    file_categories: [],
    publication: null,
    initialStep: '',
    shouldResetFlowOnSubmit: true,
    files: [],
    validateOnCategoryChange: (f: File[] | Attachment[]) => null,
    validateOnChange: (f: File | Attachment) => null,
  },
  leavePageWarn: false,
  reviewer: false,
  editor: false,
  login: { visible: false, data: {}},
  signup: { visible: false, data: {}, formValues: {}, provider: null },
  beforeForgot: false,
  afterForgot: false,
  drawer: true,
  confirmPublicationStatus: { visible: false, status: null },
  reviewerDetails: { visible: false, data: {}},
  inviteReviewer: { visible: false, data: {}},
  previewInviteReviewer: { visible: false, data: {}, onConfirm: () => null },
  deleteJournalReviewDraft: { visible: false, data: {}, onDelete: () => null },
  reviewComment: { visible: false, data: {}},
  replicaOverview: { visible: false, data: {}},
  reviewsOverview: { visible: false, data: {}},
  submitNewPublicationVersion: { visible: false, data: { comment: null, files: null }},
  manageEditors: false,
  aditionalProfileInfo: false,
  reviewerQuestionsForm: {
    visible: false,
    reviewID: null,
    publicationID: null,
    isPreview: false,
  },
  rejectAndTransferManuscriptModal: { visible: false },
  announcementsList: { visible: false },
  malwareProtection: false,
  blindfoldedJournalFlow: { visible: false, data: { publication: null, onSuccess: () => null }},
  submitManuscriptAlert: { visible: false, data: { errorTitle: '', errorMessage: '' }},
  deleteAccount: false,
  invitesDetails: { visible: false, data: null },
  blindfoldedFiles: { visible: false, data: {}},
}

export type TAppStatus = {
  status: AppStatusStates
  ready: boolean
  modals: typeof modals
  isDev: boolean
  canGoBlank: boolean
  authFinished: boolean
  hasResolvedInitialAuth: boolean
}

const initialState: TAppStatus = {
  status: 'splash',
  ready: false,
  modals,
  canGoBlank: true,
  authFinished: false,
  isDev: false,
  hasResolvedInitialAuth: false,
}

export type ModalName = keyof TAppStatus['modals']

export const appStatusSlice = createSlice({
  name: 'AppStatus',
  initialState,
  reducers: {
    initialAuthResolved() {
      return {
        hasResolvedInitialAuth: true,
      }
    },
    setModal(state, modal: [ModalName, boolean, any?] | ModalName) {
      const [name, value, data] = Array.isArray(modal) ? modal : [modal, modals[modal] instanceof Object ? !state.modals[modal]?.visible : !state.modals[modal]]

      if (data instanceof Object || modals[name] instanceof Object) {
        return {
          modals: { [name]: { ...state.modals[name], visible: value, ...data }},
        }
      } else {
        return { modals: { [name]: value }}
      }
    },
    setReady(state, to: boolean) {
      return {
        ready: to,
      }
    },
    toggleDevMode(state, setTo = null) {
      let newState = !state.isDev

      if (TypeGuards.isBoolean(setTo)) {
        newState = setTo
      }

      api.setConfig({
        baseURL: newState ? Settings.Fetch.DevelopmentURL : Settings.Fetch.ProductionURL,
      })

      LocalStorage.setItem('SESSION_IS_DEV', newState ? 'true' : 'false')

      return {
        isDev: newState,
      }
    },
    clearModals(state) {
      return {
        modals,
      }
    },
  },
  asyncReducers: {
    async authFinished(state, setState, to = true) {
      setState({ status: 'done', authFinished: to, modals: initialState.modals })
    },
    async set(state, setState, status: TAppStatus['status']) {

      // HACK this fixes the animation transition blinking between the splash screen and idle
      if (status === 'idle' && state.status === 'splash') {
        setTimeout(() => setState({ status: 'idle' }), 500)
        setState({ status: 'blank' })
      } else {
        setState({ status })
      }

    },
    async transitionModals(state, setState, [from, to]: [ModalName, ModalName]) {
      const timeout = state?.status !== 'idle' ? 2500 : 400

      const modals = { ...state.modals }

      if (TypeGuards.isBoolean(modals[from])) {
        modals[from] = false
      } else {
        modals[from].visible = false
      }

      setState({ modals })

      setTimeout(() => {
        if (TypeGuards.isBoolean(modals[to])) {
          modals[to] = true
        } else {
          modals[to].visible = true
        }
        setState({ modals })
      }, timeout)
    },
  },
})
