import React, { useState, useEffect, forwardRef } from 'react'
import { EmptyPlaceholder } from '@codeleap/web'
import { useTable } from '@/utils'
import { TableProps } from './types'
import { TableFooter } from './TableFooter'
import { TableRow } from './TableRow'
import { TableHead } from './TableHead'

import './styles.css'

const TableComponent = forwardRef((props: TableProps, ref) => {
  const {
    columnMap,
    onRowPress = null,
    items,
    showHeader = true,
    showPlaceholder = true,
    placeholderProps,
    PlaceholderComponent,
    header,
    ...rest
  } = props

  const { mainColumnWidth } = useTable()

  const hasItems = !!items && items?.length >= 1

  return (
    <>
      <div className='table-container' id='table-container' ref={ref}>
        {header}
        {hasItems ? (
          <table className='responsive-table'>
            <TableHead columnMap={columnMap} showHeader={showHeader} />
            <tbody id='body'>
              {items?.map?.((item) => (
                <TableRow
                  key={item?.id + 'table-row'}
                  item={item}
                  columnMap={columnMap}
                  onPress={onRowPress}
                  mainColumnWidth={mainColumnWidth}
                />
              ))}
            </tbody>
          </table>
        ) : (
          showPlaceholder && <PlaceholderComponent {...placeholderProps} debugName='table placeholder' />
        )}
      </div>
      <TableFooter {...rest} items={items} />
    </>
  )
})

export const Table = forwardRef((props: TableProps, ref) => {
  const { columnMap: _columnMap, isLoading, PlaceholderComponent = EmptyPlaceholder } = props

  const columnMap = _columnMap?.filter?.((c) => !!c)

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    // @note fix placeholder glitch
    setLoaded(false)

    if (!isLoading) {
      setTimeout(() => {
        setLoaded(true)
      }, 1000)
    }
  }, [isLoading])

  if (!loaded) {
    return <PlaceholderComponent loading {...props?.placeholderProps} debugName='table placeholder' />
  }

  return <TableComponent {...props} columnMap={columnMap} PlaceholderComponent={PlaceholderComponent} ref={ref}/>
})

export * from './TableRow'
export * from './types'
export * from './defaultComponents'
