import { PossibleReviewer, Publication } from '@/types'
import { PaginationResponse, QueryManager, UsePaginationTypes, onUpdate } from '@codeleap/common'
import { api } from '@/app'
import { queryClient } from '../../queryClient'
import { useTableProps } from '@/utils'

const BASE_URL = 'article/possible_reviewer/'

type ListFilterProps = {
    article: Publication['id']
}

async function list(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<PossibleReviewer>>(BASE_URL, {
    params,
  })

  return response.data
}

async function retrieve(id: PossibleReviewer['id'], article: number) {
  const response = await api.get<PaginationResponse<PossibleReviewer>>(`${BASE_URL}${id}/?article=${article}`)

  return response.data
}

export const reviewInvitesManager = new QueryManager<PossibleReviewer>({
  itemType: {} as PossibleReviewer,
  name: 'possibleReviewers',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter: ListFilterProps) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },

  retrieveItem: async ({ id, article }: Record<'id' | 'article', number>) => { //NOTE - this only receives ID, find a different way later
    if (!id || !article) return null
    const response = await retrieve(id, article)
    return response
  },

})

type UsePossibleReviewersProps = Partial<UsePaginationTypes.PaginationParams> & {
  article: Publication['id']
}

export const usePossibleReviewers = (props: UsePossibleReviewersProps) => {
  const {
    article,
    ...paginationProps
  } = props

  const possibleReviewers = reviewInvitesManager.use({
    filter: {
      ...paginationProps,
      article,
    },
    limit: 15,
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchInterval: 3000,
      },
    },
  })

  const tableProps = useTableProps(possibleReviewers, { limit: 15 })

  return {
    possibleReviewers,
    tableProps,
  }
}
