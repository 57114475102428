import { React, Theme } from '@/app'
import { Text, Avatar, Icon, Touchable, View } from '@/components'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { TypeGuards } from '@codeleap/common'

type NamePreviewProps = {
  reviewer: any
  publicationId: number
  index?: number
  color?: string
  showMoreInfoIcon?: boolean
  amount?: number
  position?: number
  onCloseInfo?: () => void
}

export const NamePreview = (props: NamePreviewProps) => {

  const { reviewer, publicationId, index, color, position, showMoreInfoIcon = true, amount, onCloseInfo = () => null } = props

  const { isAuthor, isEditor, isPublisher } = APIClient.Session.usePermissions()

  const isEditorReview = reviewer?.full_name === 'Editor'

  const wrapperVariants = ['row', 'gap:0.75', 'alignCenter', 'justifyStart', 'fullWidth']

  const Wrapper = (showMoreInfoIcon && (isEditor || isPublisher) ? Touchable : View)

  const wrapperProps = showMoreInfoIcon ? {
    variants: wrapperVariants,
    onPress: () => {
      AppStatus.clearModals()
      AppStatus.setModal(['reviewerDetails', true, { data: { id: reviewer?.id, publication: publicationId, onClose: onCloseInfo }}])
    },
  } : { variants: wrapperVariants }

  const idx = position ?? (index ?? 0)
  const reviewerDescription = isAuthor ? `${idx + 1}` : `| Reviewer ${(idx + 1)?.toString?.()}`

  const shouldDisplayReviewerName = isAuthor && !isEditorReview
  const name = shouldDisplayReviewerName ? 'Reviewer' : reviewer.full_name

  return (
    <Wrapper {...wrapperProps} >

      <Avatar
        debugName='overviewModal'
        image={reviewer?.avatar}
        name={[reviewer?.first_name, reviewer?.last_name]}
        variants={['tiny']}
        color={color}
        firstNameOnly={shouldDisplayReviewerName}
        text={isEditorReview ? 'E' : (isAuthor ? `R${idx + 1}` : null)}
        styles={{
          initials: {
            fontSize: 8,
          },
        }}
      />

      <Text variants={['p2']} text={name} />
      {(TypeGuards.isNumber(index) && amount > 1 && !isEditorReview) ? <Text variants={[`p2`, 'noWrap']} text={reviewerDescription} /> : null}

      {(showMoreInfoIcon && !isAuthor) && <Icon name='info' variants={['size:1', 'neutral8']} />}
    </Wrapper>
  )
}
