import { createForm, yup } from '@codeleap/common'

export const CoverLetterMinLength = 15
export const CoverLetterMaxLength = 10000

export const editCoverLetter = () => createForm('editCoverLetter', {
  cover_letter: {
    type: 'text',
    validate: yup.string().notRequired().min(CoverLetterMinLength)
      .max(CoverLetterMaxLength),
  },
})

export const editPublication = () => createForm('editArticle', {
  title: {
    label: 'Title',
    type: 'text',
    debugName: 'Publication form: title',
    placeholder: 'Article title',
    validate: yup.string().nullable().required()
      .trim()
      .max(300),
  },
  short_title: {
    label: 'Short Title (Optional)',
    type: 'text',
    debugName: 'Publication form: short_title',
    placeholder: 'Article short title',
    validate: yup.string().nullable().trim()
      .max(150),
  },
  region: {
    label: 'Country',
    placeholder: 'Select a country',
    type: 'select',
    validate: yup.string().nullable().required(),
    searchable: true,
  },
  article_type: {
    label: 'Manuscript type',
    placeholder: 'Select a type',
    type: 'select',
    validate: yup.string().nullable().required(),
    searchable: true,
  },
  category: {
    label: 'Category',
    placeholder: 'Select a category',
    type: 'select',
    validate: yup.string().nullable().required(),
    searchable: true,
  },
  subcategory: {
    label: 'Subcategory',
    placeholder: 'Select a subcategory',
    type: 'select',
    validate: yup.string().nullable().required(),
    searchable: true,
  },
  keywords: {
    label: 'Keywords',
    multiline: true,
    type: 'text',
    debugName: 'Publication section: Keywords',
    validate: yup.string().max(300).required()
      .trim(),
  },
})

export const editPublicationDetails = () => createForm('editPublicationDetails', {
  ethical_approval: {
    debugName: 'publication form: ethical_approval input',
    label: 'Ethical approval',
    description: 'All articles dealing with original human or animal data must include a statement on ethics approval at the beginning of the Methods section.',
    type: 'text',
    validate: yup.string().nullable().max(1024)
      .required(),
  },
  consent: {
    debugName: 'publication form: consent input',
    label: 'Consent',
    description: "Studies on patients or volunteers require ethics committee approval and informed consent, which should be documented in the 'Methods' section of your paper.",
    type: 'text',
    validate: yup.string().nullable().max(1024)
      .required(),
  },
  funding_source: {
    debugName: 'publication form: funding_source input',
    label: 'Funding source',
    description: 'You must make reference to all relevant sources of funding concerning this article.',
    type: 'text',
    validate: yup.string().nullable().max(1024)
      .required(),
  },
  conflicts_of_interest: {
    debugName: 'publication form: conflicts_of_interest input',
    label: 'Conflicts of interest',
    description: 'You must make reference to all relevant conflicts of interest concerning this article including financial, consultant, institutional and other relationships that might lead to bias or a conflict of interest.',
    type: 'text',
    validate: yup.string().nullable().max(1024)
      .required(),
  },
  guarantor: {
    debugName: 'publication form: guarantor input',
    label: 'Guarantor',
    description: 'Please enter the name and affiliation of the guarantor responsible for overseeing the accuracy and integrity of this journal submission. Their endorsement adds credibility to your work.',
    type: 'text',
    validate: yup.string().nullable().max(1024)
      .required(),
  },
  provenance_peer_review: {
    debugName: 'publication form: provenance_peer_review input',
    label: 'Provenance',
    description: 'Please state if this article was commissioned or unsolicited.',
    type: 'text',
    validate: yup.string().nullable().max(1024)
      .required(),
  },
  data_availability_statement: {
    debugName: 'publication form: data_availability_statement input',
    label: 'Data availability statement',
    description: 'All articles published in the Journal are subject to an initial review by a Managing Editor, an Associate or other Editor or the Editor-in-Chief. Subsequent to this initial review, manuscripts will either be rejected, or two to three independent external peer reviewers recommendations will be sought.',
    type: 'text',
    validate: yup.string().nullable().max(1024)
      .required(),
  },
})
