export const TableHead = ({ columnMap, showHeader }) => {
  return (
    <thead style={{ opacity: showHeader ? 1 : 0 }}>
      <tr>
        <div/>
        {columnMap?.map?.((col, index) => (
          <th className={col?.mainColumn && 'main'} key={index}>{col?.label ?? ''}</th>
        ))}
        <div/>
      </tr>
    </thead>
  )
}
