import { RenderRichText, TextEditor, TextEditorExtensions, View } from '@/components'
import { NamePreview } from './NamePreview'
import { useEditor } from '@tiptap/react'
import { onUpdate } from '@codeleap/common'
import { AppStatus } from '@/redux'

type ReviewMessageProps = {
  message: string
  publicationId: number
  index: number
  disabled: boolean
  onChangeText?: (text) => void
  reviewers: any[]
}

export const ReviewMessage = (props: ReviewMessageProps) => {

  const { message, index, disabled, reviewers, publicationId } = props

  let timeoutId = null

  const debounce = (func, delay) => {
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args)
      }, delay)
    }
  }

  const onChangeText = (text) => {
    debounce(() => {
      if (!editor?.isFocused) {
        props?.onChangeText?.(text)
      }
    }, 800)()
  }

  const editor = useEditor({
    content: message?.comment,
    extensions: TextEditorExtensions,
    editorProps: {
      transformPastedHTML: (html) => html.replace(/<img.*?>/g, ''),
    },
    onUpdate: ({ editor }) => onChangeText(editor?.getText?.()),
  })

  onUpdate(() => {
    editor?.commands.setContent?.(message?.comment)
  }, [message, editor])

  onUpdate(() => {
    editor?.setOptions({ editable: !disabled })
  }, [disabled])

  const currentReviewerColor = reviewers.find((reviewer) => reviewer?.reviewer === message?.reviewer?.id)?.color

  return (
    <View key={`Review-comment-${message?.comment}-${index}`} variants={['fullWidth', 'column', 'gap:1']}>
      <NamePreview
        reviewer={message?.reviewer}
        publicationId={publicationId}
        index={index}
        color={currentReviewerColor}
        amount={reviewers?.length}
        onCloseInfo={() => AppStatus.setModal(['reviewsOverview', true])}
      />

      {disabled ? (
        <RenderRichText content={message?.comment ?? ''} />
      ) : (
        <TextEditor
          // @ts-ignore
          editor={editor}
          toolbarComponent={null}
          variants={['reviewOverviewForm']}
        />
      )}
    </View>
  )
}
