import { AppIcon, variantProvider } from '@/app'
import { OptionsDropDown, OptionsDropDownProps, View, Button, ActivityIndicator } from '@/components'
import { ProfileRole } from '@/types'
import { APIClient } from '@/services'
import { useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { capitalize, useBooleanToggle } from '@codeleap/common'
import { Navigation } from '@/utils'

export type RoleSelectorProps = Partial<OptionsDropDownProps> & {}

export const RoleSelector = (props: RoleSelectorProps) => {
  const { updateProfile, profile, permissions } = APIClient.Session.useSession()
  const { badges, refresh } = APIClient.Badges.useBadges(profile)
  const [visible, toggle] = useBooleanToggle(false)
  const { roles } = APIClient.Roles.useRoleSelector(profile)
  const queryClient = useQueryClient()

  const handleChange =
    async ({ role }: { role: ProfileRole }) => {
      try {
        toggle()
        if (role === profile?.current_role) return

        await updateProfile.mutateAsync({
          id: profile?.id,
          current_role: role,
        })

        queryClient.clear()
        refresh()

        Navigation.navigate('Manuscripts.List')
        setTimeout(() => {
          if (role === 'reviewer' && badges?.invites) {
            Navigation.navigate('Invites.List')
            return
          }
        }, 1000)
      } catch {

      }
    }

  const options = useMemo(() => {
    if (!roles) {
      return (
        <View variants={['center']}>
          <ActivityIndicator debugName='RoleSelector:Loading' />
        </View>
      )
    }

    return Object.entries(roles)
      .filter((entry) => !!entry[1])
      .map(([key, value]) => {
        if (!value) return null
        const selected = profile?.current_role === key

        return (
          <Button
            variants={['roleSelector']}
            text={capitalize(key ?? '')}
            debugName={`Role:${key}`}
            onPress={() => handleChange({ role: key as ProfileRole })}
            selected={selected}
            rightIcon={selected && 'check'}
            key={key}
            id={`role:${key}`}
          />
        )
      })
  }, [roles, handleChange, profile?.current_role])

  return (
    <OptionsDropDown
      debugName='RoleSelector'
      variants={['optionText:primary5', 'optionLines:none', 'textIcon:reverse', 'textIcon:spacing1']}
      tooltipProps={{
        visible,
        toggle,
        styles: {
          'content:bottom': styles.tooltipBottom,
          arrow: styles.tooltipArrow,
        },
        content: options,
        closeOnClickOutside: true,
      }}
      text={capitalize(profile?.current_role ?? '')}
      icon={'chevron-down' as AppIcon}
      options={[]}
      id='roleSelector'
      {...props}
    />
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    tooltipBottom: {
      zIndex: 99999,
      width: 220,
      ...theme.presets.elevated,
      borderRadius: theme.borderRadius.medium,
      ...theme.spacing.padding(2),
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.marginRight('110px'),
    },
    tooltipArrow: {
      display: 'none',
      width: 0,
      height: 0,
    },
  }),
  true,
)
