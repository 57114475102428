import React, { useMemo, useState } from 'react'
import { InviteNameColumnItem, Modal, ReviewerMetrics, Select, Table, Tag, Text, View } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { variantProvider } from '@/app'
import { onUpdate, TypeGuards } from '@codeleap/common'
import { useGetStyles, useTableProps } from '@/utils'
import { InvitationReviewer, InvitationStatus } from '@/types'

function StatusTag({ item }) {
  const possibleStatus: Record<InvitationStatus, any> = {
    accepted: {
      text: 'Accepted',
      css: { backgroundColor: '#DBF3E8' },
      styles: { text: { color: '#298B5C' }},
    },
    declined: {
      text: 'Declined',
      css: { backgroundColor: '#FFE5E9' },
      styles: { text: { color: '#E52727' }},
    },
    sent: {
      text: 'No response',
      css: { backgroundColor: '#F8ECDD' },
      styles: { text: { color: '#DA6716' }},
    },
    review_complete: {
      text: 'Accepted',
      css: { backgroundColor: '#DBF3E8' },
      styles: { text: { color: '#298B5C' }},
    },
  }

  return (
    <Tag
      variants={['statusTag']}
      debugName={`invite status ${item?.status}`}
      {...possibleStatus[item?.status || 'sent']}
    />
  )
}

const LIMIT = 25

export function InvitesDetails() {
  const { visible, data } = useAppSelector((s) => s.AppStatus.modals.invitesDetails)

  const [version, setVersion] = useState(0)

  const reviewers = APIClient.Publications.reviewInvitesManager.use({
    filter: {
      limit: LIMIT,
      article: data?.id,
      version: version === 0 ? data?.version : version,
      status_priority_order: 'review_complete,accepted,declined,sent',
    },
    listOptions: {
      queryOptions: {
        enabled: !!(visible && data?.id),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    },
  })

  const tableProps = useTableProps(reviewers, { limit: LIMIT })
  const { getStyles } = useGetStyles(styles)

  onUpdate(() => {
    if (data?.version && version === 0) setVersion(data?.currentVersion || data?.version)
  }, [data?.version])

  function openReviewerDetails(reviewer: InvitationReviewer) {
    AppStatus.setModal(['reviewerDetails', true, { data: {
      ...reviewer,
      id: reviewer?.profile?.id,
      publication: reviewer?.article,
    },
    }])
  }

  const fields = useMemo(() => {
    return [
      { title: 'Currently reviewing', field: data?.title },
      { title: 'Journal', field: data?.journal?.title },
      { title: 'Author', field: data?.author },
      {
        title: 'Manuscript version',
        field: () => {
          const options = Array.from({ length: data?.version || 0 }).map((_, i) => ({
            value: i + 1,
            label: `${i + 1}`,
          }))

          return (
            <Select
              options={options}
              value={version}
              placeholder={data?.version}
              onValueChange={setVersion}
              debugName='select version'
              styles={getStyles('select')}
            />
          )
        },
      },
      {
        title: 'Review metrics',
        field: () => <ReviewerMetrics version={version} article={data?.id} />,
      },
      {
        title: 'Invite status',
        field: () => {
          return (
            <Table
              {...tableProps}
              debugName='reviewers details table'
              showHeader={false}
              onRowPress={(reviewer) => openReviewerDetails(reviewer)}
              columnMap={[
                { mainColumn: true, component: (item) => <InviteNameColumnItem item={item} /> },
                { component: (item) => <StatusTag item={item} /> },
              ]}
            />
          )
        },
      },
    ]
  }, [data, version, tableProps])

  function toggle() {
    AppStatus.setModal(['invitesDetails', false])
    setTimeout(() => AppStatus.setModal(['invitesDetails', false, { data: null }]))
  }

  return (
    <Modal visible={visible} toggle={toggle} styles={getStyles('modal')} title={'Reviewers and invites details'}>
      {fields.map(({ title, field }) => (
        <View variants={['column', 'gap:1']}>
          <Text variants={['p2', 'color:neutral7']} text={title} />
          {TypeGuards.isFunction(field) ? field() : <Text variants={['p1', 'color:neutral8']} text={field} />}
        </View>
      ))}
    </Modal>
  )
}

const MAX_WIDTH = 800

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    modalBox: {
      maxWidth: MAX_WIDTH,
      ...theme.presets.fullWidth,
    },
    modalBody: {
      ...theme.presets.column,
      ...theme.spacing.gap(2),
    },

    selectWrapper: {
      ...theme.spacing.marginRight('50%'),
    },
    selectPlaceholder: {
      color: theme.colors.neutral9,
    },
    selectErrorMessage: {
      display: 'none',
    },
  }),
  true,
)
