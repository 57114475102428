/* eslint-disable max-lines */
/* eslint-disable max-len */
import { AppStatus, useAppSelector } from '@/redux'
import { Button, ExpandableView, Modal, View, Text, EvaluatePublicationButtons, TextEditor, ToolBar, TextEditorExtensions, ActionIcon } from '@/components'
import { React, OSAlert, Theme, IS_SSR } from '@/app'
import { APIClient } from '@/services'
import { TypeGuards, deepEqual, onUpdate } from '@codeleap/common'
import { useMemo, useState } from 'react'
import { Publication, Review } from '@/types'
import { ReviewAttachmentsDropzone } from './components/ReviewAttachmentsDropzone'
import { ReviewFormAnswers } from './components/ReviewFormAnswers'
import { SectionWrapper } from './components/SectionWrapper'
import { ReviewMessage } from './components/ReviewMessage'
import { generateLightColor } from '@/utils'
import { useEditor } from '@tiptap/react'
import Placeholder from '@tiptap/extension-placeholder'
import { EmptyPlaceholder } from '@codeleap/web'
import { fileSizeValidator } from '@/app/forms'
import { NewPublicationVersion } from './components/NewPublicationVersion'
import { DefinedUseQueryResult } from '@tanstack/react-query'
import { ReviewHeader } from './components/ReviewHeader'

const getManyReviewersByField = (arr?: Review[], field: keyof Review) => {
  if (!arr) return null
  return arr.reduce((acc, i) => {
    if (i?.[field]) {
      if (TypeGuards.isArray(i[field])) {
        return [
          ...acc,
          ...(i[field] as []).map(item => ({
            ...item,
            ...i.reviewer,
            id: i?.reviewer?.id,
            item: item?.id,
          })),
        ]
      }
      return [
        ...acc,
        i,
      ]
    } else {
      return acc
    }
  }, [])
}

type ReviewsOverviewProps = {
  publicationID?: Publication['id']
  publication?: Publication
  publicationQuery?: DefinedUseQueryResult<Publication, unknown> //remove
}

type OnSaveProps = {
  publication?: Publication
}
const hasHtmlChanged = (initial: string, current: string) => {
  if (IS_SSR) return false

  const divInitial = document.createElement('div')
  const divCurrent = document.createElement('div')

  divInitial.innerHTML = initial
  divCurrent.innerHTML = current

  return divInitial.innerText !== divCurrent.innerText
}

export const ReviewsOverview = (props: ReviewsOverviewProps) => {
  const { publicationID, publication, publicationQuery } = props
  const { visible, data } = useAppSelector((state) => state.AppStatus.modals.reviewsOverview)

  const [version, setVersion] = useState(data?.version ?? publication?.version ?? 0)

  const currentPublicationVersion = publication?.versions?.find(v => v?.article_version === (version || publication?.version))

  const { items: articleMedia } = APIClient.Publications.mediaManager.use({ filter: { publication: publicationID }, listOptions: { queryOptions: { enabled: visible }}})
  const { data: replicaMedia } = APIClient.Replica.useReviewReplicaMedia({ reply: currentPublicationVersion?.id }, { enabled: visible })

  const { isEditor, isPublisher, isAuthor } = APIClient.Session.usePermissions()
  const [reviews, setReviews] = useState([])
  const [reviewers, setReviewers] = useState([])

  const [currentRecommendations, setRecommendations] = useState([])

  const [editorComment, setEditorComment] = useState('')
  const [initialEditorComment, setInitialEditorComment] = useState('')

  const [draftMessages, setDraftMessages] = useState([])
  const [previewNumberedAnswers, setPreviewNumberedAnswers] = useState([])

  const [currentAttachments, _setCurrentAttachments] = useState([])

  const [reviewAttachmentsToBeDeleted, setReviewAttachmentsToBeDeleted] = useState([])
  const [publicationAttachmentsToBeDeleted, setPublicationAttachmentsToBeDeleted] = useState([])

  const editor = useEditor({
    extensions: [...TextEditorExtensions, Placeholder.configure({ placeholder: 'Text your message here', emptyEditorClass: 'placeholder' })],
    onUpdate: ({ editor }) => setEditorComment(editor?.getText() ? editor?.getHTML() : null),
  })

  const updateArticleReview = APIClient.Review.reviewManager.useUpdate()

  // const createPublicationMedia = APIClient.Publications.mediaManager.useCreate()
  const deletePublicationMedia = APIClient.Publications.mediaManager.useDelete()

  const shouldQuestionGroupFormAnswers = reviews && reviews.length > 1

  const isFirstPublicationVersion = publication?.version === 1

  const currentEditorComment = currentPublicationVersion?.editor_comment
  const isCurrentVersion = currentPublicationVersion?.article_version === publication?.version
  const shouldDisableActions = isAuthor || !isCurrentVersion
  const canSaveAttachments = !currentAttachments?.length || !currentAttachments?.find((att) => fileSizeValidator(att))?.code

  const setCurrentAttachments = (state) => {
    if (state?.length !== 0) { _setCurrentAttachments(state) }
  }

  const initialAttachments = useMemo(() => getManyReviewersByField(reviews, 'media'), [reviews?.length])

  const initalReviewMessages = useMemo(() => {
    return getManyReviewersByField(reviews, 'comment')
  }, [reviews?.length])

  const initialFormAnswers = useMemo(() => {
    const allAnswers = getManyReviewersByField(reviews, 'form_answers')
    const recommendations = allAnswers?.filter(answer => answer?.question?.type === 'multiple-choice' && answer?.question?.choices?.filter(choice => choice?.id === answer?.choice)) || []
    const numbered = allAnswers?.filter(answer => answer?.question?.type === 'number') || []
    return { recommendations, numbered, allAnswers }
  }, [reviews?.length])

  onUpdate(() => {
    if (visible && initialEditorComment && !!editor) {
      editor?.commands?.setContent(initialEditorComment)
    }
  }, [visible, initialEditorComment, editor])

  onUpdate(() => {
    if (visible && data?.version) {
      setVersion(data?.version)
    }
  }, [visible, data?.version])

  onUpdate(() => {
    if (visible) {
      setInitialEditorComment(currentEditorComment)
      editor?.commands?.setContent(currentEditorComment)
      setTimeout(() => setEditorComment(currentEditorComment), 1000) // seems like a hack
    }
  }, [visible, shouldDisableActions, currentEditorComment])

  onUpdate(() => {
    if (visible) {
      editor?.setEditable?.(!shouldDisableActions, true)
    }
  }, [visible, shouldDisableActions])

  onUpdate(() => {
    if (visible && !draftMessages?.length) {
      setDraftMessages(structuredClone(initalReviewMessages))
    }
  }, [initalReviewMessages, visible])

  onUpdate(() => {
    if (visible) {

      const currentEditorMedia = isFirstPublicationVersion ? articleMedia : replicaMedia?.results
      const editorArticleMedia = []

      currentEditorMedia?.forEach?.((article) => {
        if (article?.editor_media && article?.reply === currentPublicationVersion?.id) {
          editorArticleMedia.push(article)
        }
      })

      setCurrentAttachments(structuredClone([...initialAttachments, ...editorArticleMedia]))

    }
  }, [visible, initialAttachments, articleMedia, replicaMedia, isFirstPublicationVersion])

  onUpdate(() => {
    if (!currentRecommendations?.length) {
      setRecommendations(structuredClone(initialFormAnswers?.recommendations))
    }
  }, [initialFormAnswers])

  useMemo(() => {
    const _reviewers = []
    initialFormAnswers.allAnswers.forEach((answer, index) => {

      if (!_reviewers.find((reviewer) => reviewer?.reviewer === answer?.id)) {
        const color = generateLightColor()
        _reviewers.push({
          reviewer: answer?.id,
          position: _reviewers?.length,
          color,
        })
      }

      if (index === initialFormAnswers.allAnswers.length - 1) {
        const color = generateLightColor()
        _reviewers.push({
          editor: true,
          position: _reviewers?.length,
          color,
        })
      }

    })
    setReviewers(_reviewers)
  }, [initialFormAnswers])

  useMemo(() => {
    if (initialFormAnswers.numbered.length && reviews.length) {
      if (shouldQuestionGroupFormAnswers) {
        const numberedAnswers = initialFormAnswers.numbered.reduce((acc, answer) => {
          const questionIndex = answer?.question?.index
          if (!TypeGuards.isUndefined(questionIndex)) {
            if (TypeGuards.isArray(acc[questionIndex])) {
              const answerAlreadyIncluded = acc[questionIndex].find(previousAnswer => previousAnswer?.id === answer?.id)
              if (!answerAlreadyIncluded) {
                acc[questionIndex] = acc[questionIndex] ? [...acc[questionIndex], answer] : answer
              }
            } else {
              const shouldAddNewAnswer = acc[questionIndex]?.id !== answer?.id
              acc[questionIndex] = acc[questionIndex] && shouldAddNewAnswer ? [acc[questionIndex], answer] : answer
            }
          }
          return acc
        }, [])
        setPreviewNumberedAnswers(structuredClone(numberedAnswers).filter(Boolean))
      } else {
        setPreviewNumberedAnswers(structuredClone(initialFormAnswers.numbered))
      }
    }
  }, [initialFormAnswers, reviews, shouldQuestionGroupFormAnswers])

  onUpdate(() => {
    if (visible && currentPublicationVersion?.article) {
      if (!reviews?.length) {
        APIClient.Publications.listReviews({ article: currentPublicationVersion?.article, version: version })
          .then(res => {
            if (res.results.length !== 0) {
              setReviews(res?.results)
            }
          })
          .catch() // add OsAlert.error if needed
      }
    }
  }, [currentPublicationVersion?.article, visible, reviews, version])

  const initialAnswersGroupedByReview = useMemo(() => {

    return initialFormAnswers.allAnswers.reduce((acc, item) => {
      if (!acc[item?.review]) {
        acc[item?.review] = []
      }
      acc[item?.review].push({
        question: item?.question?.id,
        value: item?.choice ? item?.choice : item?.value,
      })
      return acc
    }, [])
  }, [])

  const initialNumberedAnswersGroupedByReview = useMemo(() => {
    return initialFormAnswers.numbered.reduce((acc, answer) => {
      const questionIndex = answer?.question?.index
      if (!TypeGuards.isUndefined(questionIndex)) {
        if (TypeGuards.isArray(acc[questionIndex])) {
          const answerAlreadyIncluded = acc[questionIndex].find(previousAnswer => previousAnswer?.id === answer?.id)
          if (!answerAlreadyIncluded) {
            acc[questionIndex] = acc[questionIndex] ? [...acc[questionIndex], answer] : answer
          }
        } else {
          const shouldAddNewAnswer = acc[questionIndex]?.id !== answer?.id
          acc[questionIndex] = acc[questionIndex] && shouldAddNewAnswer ? [acc[questionIndex], answer] : answer
        }
      }
      return acc
    }, [])
  }, [initialFormAnswers])

  const currentAnswers = useMemo(() => {

    if (shouldQuestionGroupFormAnswers) {
      return previewNumberedAnswers.reduce((acc, current) => {
        if (Array.isArray(current)) {
          acc.push(...current.map(answer => ({ ...answer })))
        } else {
          acc.push({ ...current })
        }
        return acc
      }, [])
    } else {
      return previewNumberedAnswers
    }

  }, [shouldQuestionGroupFormAnswers, previewNumberedAnswers])

  const currentFormAnswersSortedByID = useMemo(() => {
    const answers = structuredClone(currentAnswers)
    return answers.sort((a, b) => a?.id?.localeCompare?.(b?.id, undefined, { numeric: true }))
  }, [currentAnswers])

  const initialFormAnswersSortedByID = useMemo(() => {
    const answers = structuredClone(initialFormAnswers.numbered)
    return answers.sort((a, b) => a?.id?.localeCompare?.(b?.id, undefined, { numeric: true }))
  }, [initialFormAnswers])

  const hasUpdatedField = useMemo(() => {

    const editorCommentUpdate = hasHtmlChanged(initialEditorComment, editorComment)

    const answers = [
      deepEqual(currentFormAnswersSortedByID, initialFormAnswersSortedByID),
      deepEqual(currentRecommendations, initialFormAnswers.recommendations),
    ].some(x => !x)

    const comments = !deepEqual(initalReviewMessages, draftMessages)

    const attachments = [
      currentAttachments.find(attachment => !attachment?.id),
      !!reviewAttachmentsToBeDeleted.length,
      !!publicationAttachmentsToBeDeleted.length,
    ].some(x => x)

    const hasFormChanged = [
      editorCommentUpdate,
      answers,
      comments,
      attachments,
    ].some(x => x)

    return {
      editorComment: editorCommentUpdate,
      answers,
      comments,
      attachments,
      hasFormChanged,
    }

  }, [
    editorComment,
    initialEditorComment,
    currentFormAnswersSortedByID,
    initialFormAnswers,
    initalReviewMessages,
    draftMessages, ,
    currentAttachments,
    currentRecommendations,
  ])

  const onSave = async (props: OnSaveProps) => {

    const currentPublication = props?.publication || publication

    AppStatus.set('loading')

    try {
      const journalFormAnswers = []
      //We probably won't need that since we have data from version 1
      // const shouldUpdateReply = currentPublication?.versions?.length
      // const currentReplyID = currentPublication?.versions[currentPublication?.versions?.length - 1]?.id

      if (hasUpdatedField?.editorComment) {
        // if (shouldUpdateReply) {
        APIClient.Replica.updateReplica({
          id: currentPublicationVersion?.id,
          article: currentPublication?.id,
          editor_comment: editorComment,
          status: 'submitted',
        })
        // } else {
        // updatePublication.update({
        // id: currentPublication?.id,
        // editor_comment: editorComment,
        // })
      //   }
      }

      if (hasUpdatedField?.attachments) {
        const newAttachments = currentAttachments.filter(attachment => !attachment.id && !!Object.keys(attachment)?.length && !TypeGuards.isArray(attachment))
        // if (shouldUpdateReply) {
        APIClient.Replica.PostReplicaMedia({
          files: newAttachments,
          reply: currentPublicationVersion?.id,
        })
        // }
        //  else {
        //   createPublicationMedia.create({ publication: currentPublication?.id, files: newAttachments })
        // }
      }

      if (reviewAttachmentsToBeDeleted.length) {
        APIClient.Review.onDeleteMediaAttachment({ media: reviewAttachmentsToBeDeleted })
      }

      if (publicationAttachmentsToBeDeleted.length) {
        deletePublicationMedia.delete(publicationAttachmentsToBeDeleted)
      }

      if (hasUpdatedField?.answers) {
        currentAnswers?.forEach?.((item) => {

          const shouldIncludeID = !(journalFormAnswers?.find?.(answer => answer?.id === item?.id))

          if (shouldIncludeID) {
            journalFormAnswers.push({
              id: item?.id,
              review: item?.review,
              answers: null,
            })
          }

          const itemToInclude = {
            question: item?.question?.id,
            value: item?.value,
          }

          const answerIndex = journalFormAnswers.findIndex(answer => answer?.id === item?.id)
          const currentJournalFormAnswers = journalFormAnswers[answerIndex]?.answers
          if (currentJournalFormAnswers) {
            journalFormAnswers[answerIndex].answers = [...currentJournalFormAnswers, itemToInclude]
          } else {
            journalFormAnswers[answerIndex].answers = [itemToInclude]
          }

        })

        journalFormAnswers.map(async (answer) => {

          const { id, ...data } = answer
          const currRecomendation = currentRecommendations.find((recomendation) => recomendation?.id === answer?.id)
          const recommendationQuestion = currRecomendation?.question?.id
          const recommendationValue = currRecomendation?.choice

          const items = { ...data }
          if (TypeGuards.isArray(items?.answers)) {
            items.answers = [...items?.answers]
            if (!!recommendationQuestion && !!recommendationValue) {
              items.answers = [...items.answers, { question: recommendationQuestion, value: recommendationValue }]
            }
          } else {
            items.answers = [items?.answers]
            if (!!recommendationQuestion && !!recommendationValue) {
              items.answers = [...items.answers, { question: recommendationQuestion, value: recommendationValue }]
            }
          }
          const shouldEditReviewAnswers = !deepEqual(initialAnswersGroupedByReview[items?.review], items?.answers)
          if (shouldEditReviewAnswers) {
            APIClient.Review.JournalAnswers(items)
          }
        })

      }

      if (hasUpdatedField?.comments) {
        draftMessages.filter(draft => {
          const previousReviewMessage = initalReviewMessages?.find((reviewMessage) => reviewMessage?.id === draft?.id)
          const hasChangedMessage = draft?.comment !== previousReviewMessage?.comment
          if (hasChangedMessage) {
            updateArticleReview.update({
              id: draft?.id,
              article: draft?.article,
              comment: draft?.comment,
              status: 'submitted',
            })
          }
        })
      }

    } catch (e) {

    } finally {
      await APIClient.Publications.publicationHistoryManager.refresh({ filter: { article: publicationID }})
      toggle()
      AppStatus.set('done')
      await publicationQuery.refetch()
      await APIClient.Replica.replicaManager?.refresh()
    }
  }

  const toggle = () => {
    AppStatus.setModal(['reviewsOverview', !visible, { data: null }])
    setTimeout(() => {
      editor?.commands?.setContent('')
      setVersion(publication?.version)
      setReviews([])
      setReviewers([])
      setRecommendations([])
      setEditorComment('')
      setInitialEditorComment('')
      _setCurrentAttachments([])
      setDraftMessages([])
      setPreviewNumberedAnswers([])
      setReviewAttachmentsToBeDeleted([])
      setPublicationAttachmentsToBeDeleted([])
    })
  }

  const onClearModalChanges = () => {
    if (hasUpdatedField?.attachments) {
      setCurrentAttachments(structuredClone(initialAttachments))
    }
    if (hasUpdatedField?.comments) {
      setDraftMessages(structuredClone(initalReviewMessages))
    }
    if (hasUpdatedField?.answers) {
      setPreviewNumberedAnswers(structuredClone(initialNumberedAnswersGroupedByReview))
    }
  }

  const onCancelToggle = () => {
    setCurrentAttachments(structuredClone(initialAttachments))
    AppStatus.setModal(['reviewsOverview', true, { data }])
  }

  const onToggleModal = () => {
    toggle()
    if (hasUpdatedField?.hasFormChanged) {
      OSAlert.ask({
        title: 'Close and discard changes?',
        body: `By closing this modal you will lose all changes. This action can't be undone.`,
        options: [
          { text: 'Cancel', onPress: onCancelToggle, variants: ['flat', 'large'] },
          { text: 'Close', onPress: onClearModalChanges, variants: ['large'] },
        ],
      })
    }
  }

  const onUpdateMessage = ({ text, index }) => {
    setDraftMessages((state) => {
      const items = [...state]
      items[index].comment = text
      return items
    })
  }

  return (
    <>
      <Modal
        toggle={onToggleModal}
        visible={visible}
        showClose={false}
        dismissOnBackdrop={true}
        variants={['reviewOverview']}
      >

        <View variants={['gap:4', 'column', 'fullWidth']}>

          <View variants={['fullWidth', 'justifySpaceBetween']}>
            <Text variants={[`h2`]} text={isAuthor ? 'View decision' : 'Submit decision'} />

            {!shouldDisableActions ? (
              <View variants={['gap:2']}>

                {(isEditor || isPublisher) && <Button
                  variants={['small', 'flat', 'backgroundColor:primary5']}
                  text='Save'
                  onPress={onSave}
                  disabled={!hasUpdatedField?.hasFormChanged || shouldDisableActions || !canSaveAttachments}
                  styles={{ text: { color: Theme.colors.light.neutral1 }}}
                  debugName={'Review Overview Modal - Save Final Review'}
                />}

                <ActionIcon
                  variants={['medium', 'minimal']}
                  icon='x'
                  onPress={onToggleModal}
                  debugName={'Review Overview Modal - Close Review Modal'}
                />

              </View>
            ) : null}
          </View>

          <ReviewHeader publication={publication} version={version} setVersion={setVersion} toggle={toggle} showVersionControl={data?.historyVersion} />

          <ExpandableView initialState title='Recommendations'>
            <ReviewFormAnswers
              recommendations={currentRecommendations}
              setRecommendations={setRecommendations}
              disabled={shouldDisableActions}
              reviewers={reviewers}
              publicationId={publicationID}
            />
          </ExpandableView>

          <ExpandableView initialState title='Submission form'>
            <ReviewFormAnswers
              formAnswers={previewNumberedAnswers}
              setFormAnswers={setPreviewNumberedAnswers}
              disabled={shouldDisableActions}
              reviewers={reviewers}
              showEmptyPlaceholder={!previewNumberedAnswers?.length}
              publicationId={publicationID}
            />
          </ExpandableView>

          <ReviewAttachmentsDropzone
            attachments={currentAttachments}
            setAttachments={setCurrentAttachments}
            attachmentsToBeDeleted={reviewAttachmentsToBeDeleted}
            setAttachmentsToBeDeleted={setReviewAttachmentsToBeDeleted}
            publicationAttachmentsToBeDeleted={publicationAttachmentsToBeDeleted}
            setPublicationAttachmentsToBeDeleted={setPublicationAttachmentsToBeDeleted}
            disabled={shouldDisableActions}
            reviewers={reviewers}
          />

          <ExpandableView initialState title={draftMessages?.length < 1 ? 'Comments' : 'Reviewer Comments'}>
            {draftMessages?.length < 1 && shouldDisableActions && !editorComment ? (
              <EmptyPlaceholder icon='file-text' variants={['table']} title='No Comments provided.' />
            ) : (
              <View variants={['backgroundColor:neutral2', 'padding:2', 'border-radius:small']}>
                <SectionWrapper variants={['column']}>
                  <>
                    {draftMessages?.map((message, index) => {
                      return (
                        <ReviewMessage
                          message={message}
                          index={index}
                          onChangeText={(text) => onUpdateMessage({ text, index })}
                          disabled={shouldDisableActions}
                          reviewers={reviewers}
                          publicationId={publicationID}
                        />
                      )
                    })}

                    {((editor?.getText()?.length < 1) && !editor?.isEditable) ? null : (
                      <View variants={['column', 'gap:1']}>
                        <Text variants={['p2', 'neutral-8']} text='Editor comment' />
                        <View variants={['bg:neutral1', 'border-radius:tiny']}>
                          <TextEditor
                            editor={editor}
                            toolbarComponent={<ToolBar editor={editor} excludeIds={['image', 'link', 'fileComponent']} variants={['reviewCommentModal']} hide={shouldDisableActions} />}
                            variants={['reviewCommentModal', 'disabledPlain', shouldDisableActions && 'flat']}
                          />
                        </View>
                      </View>
                    )}
                  </>
                </SectionWrapper>
              </View>
            )}
          </ExpandableView>

          {isCurrentVersion ? (
            (isEditor || isPublisher) ? (
              <EvaluatePublicationButtons
                publication={publication?.id}
                onAcceptPublication={onSave}
                onRejectPublication={onSave}
                onRevisePublication={onSave}
                onWithdrawPublication={onSave}
                disabled={!canSaveAttachments}
              />
            ) : (
              <NewPublicationVersion publication={publication}/>
            )
          ) : null}

        </View >
      </Modal >
    </>
  )
}

