export const imageIcons = {
  'add-file': require('./add-file.svg'),
  'align-center': require('./align-center.svg'),
  'align-justify': require('./align-justify.svg'),
  'align-left': require('./align-left.svg'),
  'align-right': require('./align-right.svg'),
  'apple': require('./apple.svg'),
  'archive': require('./archive.svg'),
  'arrow-bar-left': require('./arrow-bar-left.svg'),
  'arrow-left': require('./arrow-left.svg'),
  'arrow-right': require('./arrow-right.svg'),
  'arrow-up-down': require('./arrow-up-down.svg'),
  'arrow-up': require('./arrow-up.svg'),
  'arrow-down': require('./arrow-down.svg'),
  'article': require('./article.svg'),
  'bell': require('./bell.svg'),
  'bold': require('./bold.svg'),
  'book-open': require('./book-open.svg'),
  'book': require('./book.svg'),
  'bug': require('./bug.svg'),
  'building': require('./building.svg'),
  'camera': require('./camera.svg'),
  'check-circle': require('./check-circle.svg'),
  'check-circle-2': require('./check-circle-2.svg'),
  'check': require('./check.svg'),
  'chevron-down': require('./chevron-down.svg'),
  'chevron-left': require('./chevron-left.svg'),
  'chevron-right': require('./chevron-right.svg'),
  'chevron-up': require('./chevron-up.svg'),
  'clipboard-list': require('./clipboard-list.svg'),
  'clipboard': require('./clipboard.svg'),
  'clock': require('./clock.svg'),
  'cloud-lightning': require('./cloud-lightning.svg'),
  'cloud': require('./cloud.svg'),
  'contact': require('./contact.svg'),
  'contrast': require('./contrast.svg'),
  'download': require('./download.svg'),
  'edit-2': require('./edit-2.svg'),
  'edit': require('./edit.svg'),
  'expand': require('./expand.svg'),
  'eye-off': require('./eye-off.svg'),
  'package-open': require('./package-open.svg'),
  'eye': require('./eye.svg'),
  'eyeglass-2': require('./eyeglass-2.svg'),
  'facebook': require('./facebook.svg'),
  'feather': require('./feather.svg'),
  'file-edit': require('./file-edit.svg'),
  'file-text': require('./file-text.svg'),
  'file-plus': require('./file-plus.svg'),
  'file': require('./file.svg'),
  'filter': require('./filter.svg'),
  'fingerprint': require('./fingerprint.svg'),
  'flask-conical': require('./flask-conical.svg'),
  'floppy-disk': require('./floppy-disk.svg'),
  'folder': require('./folder.svg'),
  'font-color': require('./font-color.svg'),
  'google': require('./google.svg'),
  'grip-vertical': require('./grip-vertical.svg'),
  'heading-1': require('./heading-1.svg'),
  'heading-2': require('./heading-2.svg'),
  'heading-3': require('./heading-3.svg'),
  'heading-4': require('./heading-4.svg'),
  'heading-5': require('./heading-5.svg'),
  'heading-6': require('./heading-6.svg'),
  'heart': require('./heart.svg'),
  'highlighter': require('./highlighter.svg'),
  'history': require('./history.svg'),
  'image': require('./image.svg'),
  'info': require('./info.svg'),
  'italic': require('./italic.svg'),
  'key': require('./key.svg'),
  'lamp-floor': require('./lamp-floor.svg'),
  'languages': require('./languages.svg'),
  'layers': require('./layers.svg'),
  'leaf': require('./leaf.svg'),
  'license': require('./license.svg'),
  'link-2': require('./link-2.svg'),
  'linkedin': require('./linkedin.svg'),
  'list-ordered': require('./list-ordered.svg'),
  'list': require('./list.svg'),
  'loader-2': require('./loader-2.svg'),
  'loader': require('./loader.svg'),
  'log-out': require('./log-out.svg'),
  'mail-open': require('./mail-open.svg'),
  'mail': require('./mail.svg'),
  'map-pin': require('./map-pin.svg'),
  'megaphone': require('./megaphone.svg'),
  'menu': require('./menu.svg'),
  'message-circle': require('./message-circle.svg'),
  'mic': require('./mic.svg'),
  'minus': require('./minus.svg'),
  'more-horizontal': require('./more-horizontal.svg'),
  'more-vertical': require('./more-vertical.svg'),
  'palette': require('./palette.svg'),
  'paperclip': require('./paperclip.svg'),
  'paragraph-1': require('./paragraph-1.svg'),
  'paragraph-2': require('./paragraph-2.svg'),
  'paragraph-3': require('./paragraph-3.svg'),
  'parchment': require('./parchment.svg'),
  'placeholderNoItems_select': require('./placeholderNoItems_select.png'),
  'placeholder_select': require('./placeholder_select.png'),
  'plus-circle': require('./plus-circle.svg'),
  'plus': require('./plus.svg'),
  'publication': require('./publication.svg'),
  'read': require('./read.svg'),
  'review': require('./review.svg'),
  'save': require('./save.svg'),
  'search': require('./search.svg'),
  'send': require('./send.svg'),
  'settings-2': require('./settings-2.svg'),
  'settings': require('./settings.svg'),
  'share': require('./share.svg'),
  'star': require('./star.svg'),
  'strikethrough': require('./strikethrough.svg'),
  'trash': require('./trash.svg'),
  'under-review': require('./under-review.svg'),
  'underline': require('./underline.svg'),
  'upload': require('./upload.svg'),
  'user-check': require('./user-check.svg'),
  'user-plus': require('./user-plus.svg'),
  'user': require('./user.svg'),
  'wifi-off': require('./wifi-off.svg'),
  'wifi': require('./wifi.svg'),
  'x-circle': require('./x-circle.svg'),
  'x': require('./x.svg'),
  'file-lock': require('./file-lock.svg'),
  'file-warning': require('./file-warning.svg'),
  'file-x': require('./file-x.svg'),
  'alert-circle': require('./alert-circle.svg'),
  'files': require('./files.svg'),
  'text-cursor': require('./text-cursor.svg'),
  'calendar': require('./calendar.svg'),
  'chevron-up-fill-positive': require('./chevron-up-fill.svg'),
  'chevron-down-fill-negative': require('./chevron-down-fill.svg'),
  'shield-check': require('./shield-check.svg'),
  'shield': require('./shield.svg'),
  'shield-close': require('./shield-close.svg'),
  'shield-alert': require('./shield-alert.svg'),
  'book-off': require('./book-off.svg'),
  'arrows-diff': require('./arrows-diff.svg'),
  'logo-hat': require('./logo-hat.svg'),
  'clipboard-type': require('./clipboard-type.svg'),
  'eworkflow-outline': require('./eworkflow-outline.svg'),
  'pen-tool': require('./pen-tool.svg'),
  'undo-2': require('./undo-2.svg'),
  'previous': require('./previous.svg'),
  'external-link': require('./external-link.svg'),
}
