import { Settings, variantProvider } from '@/app'
import { AppStatus, useAppSelector } from '@/redux'
import { filterNonEmptyKeys, PublicationUtils, removeFields, scrollToAnchor } from '@/utils'
import { Button, Modal, Text, View } from '@codeleap/web'
import { usePublicationForm } from '../Publication'
import { TypeGuards } from '@codeleap/common'

const fieldsToRemove = ['short_title', 'subcategory']

const fieldAnchors = {
  cover_letter: 'cover-letter-anchor',
  publicationFields: 'article-anchor',
  detailFields: 'details-anchor',
  disclaimers: 'disclaimers-anchor',
  authors: 'authors-anchor',
}

export const SubmitManuscriptAlertModal = () => {
  const { authorForms, forms, disclaimersTitle, suggestReviewerForms, selectRefs } = usePublicationForm()

  const { coverLetter, detail, publication: publicationForm } = forms
  const { fieldErrors: coverLetterFieldErrors } = coverLetter
  const { fieldErrors: publicationFieldErrors } = publicationForm
  const { fieldErrors: detailFieldErrors } = detail

  const { visible, data } = useAppSelector(state => state.AppStatus.modals.submitManuscriptAlert)
  const { errorTitle, errorMessage } = data

  const title = errorTitle || 'Something went wrong'
  const description = errorMessage || `Please complete all required fields before proceeding.\n\nIf the issue persists, save your draft, refresh the page, and try again. Contact ${Settings.ContactINFO.SupportEMAIL} if the problem continues.`

  const toggle = () => AppStatus.setModal('submitManuscriptAlert')

  const isDisclaimerNotChecked = disclaimersTitle.length > 0

  const publicationFields = Object.keys(publicationFieldErrors)
  const detailFields = Object.keys(detailFieldErrors)
  const authorFields = authorForms.map(author => Object.keys(author))?.flat()
  const reviewerFields = suggestReviewerForms.map(reviewer => Object.keys(reviewer))?.flat()

  const invalidFields = [coverLetterFieldErrors, removeFields(publicationFieldErrors, fieldsToRemove), detailFieldErrors]
  const missingValueFields = [...filterNonEmptyKeys(invalidFields), ...disclaimersTitle, authorForms]

  function handleConfirm() {
    PublicationUtils.handleFocusBlur({ fields: publicationFields, prefix: 'form-editArticle' })
    PublicationUtils.handleFocusBlur({ fields: detailFields, prefix: 'form-editPublicationDetails' })
    PublicationUtils.handleFocusBlur({ fields: authorFields, prefix: 'form-createAuthorManualInvite', hasMultipleForms: true })
    PublicationUtils.handleFocusBlur({ fields: reviewerFields, prefix: 'form-createManualInvite', hasMultipleForms: true })

    const getAnchor = (field) => {
      const isAuthorsFields = TypeGuards.isArray(field)

      if (field === 'cover_letter') {
        return fieldAnchors.cover_letter
      }
      if (publicationFields.includes(field)) {
        return fieldAnchors.publicationFields
      }
      if (isAuthorsFields) {
        const isAuthorField = field?.some(obj => Object.keys(obj)?.some(key => authorFields.includes(key)))

        if (isAuthorField) {
          return fieldAnchors.authors
        }
      }
      if (detailFields.includes(field)) {
        return fieldAnchors.detailFields
      }
      return null
    }

    const matchingAnchor = missingValueFields
      .map(getAnchor)
      .find(anchor => anchor !== null)

    if (matchingAnchor) {
      scrollToAnchor(matchingAnchor)
    } else if (isDisclaimerNotChecked) {
      scrollToAnchor(fieldAnchors.disclaimers)
    }

    Object.values(selectRefs.current)?.forEach(ref => ref?.current.showError())

    toggle()
  }

  return (
    <Modal
      toggle={toggle}
      debugName='Submit new Manuscript version Modal'
      variants={['centered']}
      visible={visible}
      showClose={false}
      closable={false}
      closeOnEscape={false}
      styles={{
        box: {
          width: 500,
        },
      }}
    >
      <Text text={title} variants={['justifyCenter', 'textCenter', 'h3']} />
      <Text text={description} variants={['justifyCenter', 'textCenter', 'p1', 'marginTop:1']} styles={{ text: { whiteSpace: 'pre-line' }}} />
      <View style={styles.container}>
        <Button
          text={'OK'}
          variants={['fullWidth', 'marginTop:2']}
          debugName={'OK button from alert modal'}
          onPress={handleConfirm}
        />
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  container: {
    ...theme.spacing.marginTop(1),
    ...theme.presets.justifyCenter,
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
}), true)
