import { createTheme, validateTheme, VariantProvider } from '@codeleap/common'
import { textStyles } from './textStyles'
import { Icons } from './assets/icons'
import { logger } from './logger'
import { CSSObject } from '@emotion/react'
import { effects } from './effects'

const themeSpacing = 8

const light = {
  'primary1': '#E7E7E7',
  'primary2': '#9F9F9F',
  'primary3': '#6F6F6F',
  'primary4': '#3F3F3F',
  'primary5': '#0F0F0F',
  'secondary1': '#E4E4F8',
  'secondary2': '#B3B4F8',
  'secondary3': '#8183F8',
  'secondary4': '#6668C4',
  'secondary5': '#4C4D91',
  'neutral1': '#FFFFFF',
  'neutral2': '#F2F2F2',
  'neutral3': '#E5E5E5',
  'neutral4': '#D9D9D9',
  'neutral5': '#CCCCCC',
  'neutral6': '#B3B3B3',
  'neutral7': '#999999',
  'neutral8': '#666666',
  'neutral9': '#333333',
  'neutral10': '#000000',
  'positive1': '#DBF3E8',
  'positive2': '#42C586',
  'positive3': '#17A05F',
  'warning1': '#F5F0D1',
  'warning2': '#E4C000',
  'warning3': '#F0A638',
  'alert1': '#F5E5D1',
  'alert2': '#F08538',
  'alert3': '#F1743A',
  'alert4': '#F25D3D',
  'destructive1': '#F3DBDB',
  'destructive2': '#F33F3F',
  'info1': '#DBEAF3',
  'info2': '#0095D6',
  'background': '#FFFFFF',
  'card': '#FFFFFF',
  'separator': '#E5E5E5',
  'border': '#CCCCCC',
  'overlay': '#000000',
  'headlines': '#333333',
  'body': '#666666',
  'caption': '#999999',
  ripple: '#0002',
  transparent: '#FFF0',
  'blue1': '#D2ECF8',
  'blue2': '#007DB3',
  'orange1': '#F8ECDD',
  'orange2': '#DA6716',
  'green1': '#DBF3E8',
  'green2': '#298B5C',
  'red1': '#FFE5E9',
  'red2': '#E52727',
}

const dark = {
  'primary1': '#173740',
  'primary2': '#286070',
  'primary3': '#4FBCDB',
  'primary4': '#43A2BD',
  'primary5': '#A9D0DB',
  'secondary1': '#2F2F59',
  'secondary2': '#434480',
  'secondary3': '#8B8DF8',
  'secondary4': '#7475B2',
  'secondary5': '#A3A4CC',
  'neutral1': '#1A1A1A',
  'neutral2': '#333333',
  'neutral3': '#4D4D4D',
  'neutral4': '#D9D9D9',
  'neutral5': '#737373',
  'neutral6': '#B3B3B3',
  'neutral7': '#999999',
  'neutral8': '#CCCCCC',
  'neutral9': '#333333',
  'neutral10': '#FFFFFF',
  'positive1': '#3D6652',
  'positive2': '#42C586',
  'warning1': '#66603D',
  'warning2': '#E4C000',
  'alert1': '#663D3D',
  'alert2': '#FF4E4E',
  'destructive1': '#663D3D',
  'destructive2': '#FF4E4E',
  'info1': '#DBEAF3',
  'info2': '#0095D6',
  'background': '#1A1A1A',
  'card': '#333333',
  'separator': '#4D4D4D',
  'border': '#737373',
  'overlay': '#000000',
  'headlines': '#FFFFFF',
  'body': '#B3B3B3',
  'caption': '#666666',
  ripple: '#0002',
  transparent: '#FFF0',
  'blue1': '#D2ECF8',
  'blue2': '#007DB3',
  'orange1': '#F8ECDD',
  'orange2': '#DA6716',
  'green1': '#DBF3E8',
  'green2': '#298B5C',
  'red1': '#FFE5E9',
  'red2': '#E52727',
}

const themeObj = validateTheme({
  colors: { light, dark },
  initialTheme: 'light',
  spacing: themeSpacing,
  borderRadius: {
    nanoish: 4,
    nano: 6,
    tiny: 10,
    smallish: 12,
    small: 16,
    medium: 24,
    large: 32,
    rounded: 999999,
  },
  typography: {
    base: {
      fontFamily: 'Inter',
      styles: textStyles,
    },
    quotes: {
      fontFamily: 'Inter',
      styles: textStyles,
    },
  },
  icons: Icons,
  presets: {
    debugger: function (color: 'blue' | 'red' | 'yellow' | 'green' | 'purple' = 'red', background = false) {
      const hex = color === 'purple' ? '#9400D3' : color

      return {
        borderWidth: 1,
        borderColor: hex,
        color: hex,
        ...(background ? { backgroundColor: hex } : {}),
      }
    },
  },
  effects,
  breakpoints: {
    zero: 0,
    tinyest: 290,
    tiny: 350,
    smallish: 420,
    small: 600,
    midish: 800,
    mid: 900,
    largeish: 1050,
    large: 1200,
    xlarge: 1400,
    xxlarge: 1800,
    huge: 2559,
  },

  values: {
    maxContentWidth: 1600,
    height: 10,
    width: 10,
    pixel: 1,
    innerSpacing: { X: 2, Y: 2, value: 16 },
    outerSpacing: { X: 2, Y: 2, value: 16 },
    gap: 2,
    smallGap: 1,
    itemHeight: {
      large: 64,
      default: 48,
      mid: 40,
      small: 32,
      tiny: 20,
    },
    iconSize: {
      1: 16,
      2: 20,
      3: 24,
      4: 32,
      5: 48,
      6: 64,
      7: 28,
    },
    headerHeight: 104,
    sideBarWidth: 320,
    sideMenuWidth: 300,
    logoSize: {
      mobile: 32,
      default: 260,
    },
    borderWidth: {
      small: 1,
      medium: 2,
      large: 5,
    },
  },
})

const appTheme = createTheme(themeObj, {
  screenSize: () => [0, 0],
})

export type TCSS = CSSObject

const styleGetter = (
  style: TCSS,
) => {

  return style
}

type StyleGetter = typeof styleGetter

export const variantProvider = new VariantProvider<
  StyleGetter,
  typeof themeObj
>(appTheme, styleGetter, logger)

export const Theme = variantProvider.theme

export type AppThemeModes = keyof typeof themeObj.colors

export type Breakpoint = keyof typeof themeObj.breakpoints
