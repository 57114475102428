import { api } from '@/app'
import { Publication } from '@/types'

const BASE_URL = '/article/reviewers_metrics/'

type Params = {
  article: Publication['id']
  version: Publication['version']
}

export async function getReviewersMetrics(params: Params): Promise<{
  reviewers_invited: number
  invites_accepted: number
  reviews_submitted: number
}> {
  const response = await api.get(`${BASE_URL}`, { params })

  return response.data
}
