import { ActionIconComposition, ActionIconPresets } from '@codeleap/web'
import { variantProvider } from '..'

const createActionIconStyle = variantProvider.createVariantFactory<ActionIconComposition>()

export const AppActionIconStyles = {
  ...ActionIconPresets,
  default: createActionIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
      color: theme.colors.neutral1,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    'touchableWrapper': {
      width: theme.values.itemHeight.default,
      height: theme.values.itemHeight.default,
      borderRadius: theme.borderRadius.small,
      ...theme.presets.center,
      backgroundColor: theme.colors.primary5,
      flexShrink: 0,
    },
    'touchableWrapper:pressable': {
      cursor: 'pointer',
    },
    'touchableWrapper:disabled': {
      cursor: 'not-allowed',
      backgroundColor: theme.colors.neutral2,
    },
  })),
  small: createActionIconStyle((theme) => ({
    touchableWrapper: {
      width: theme.values.itemHeight.small,
      height: theme.values.itemHeight.small,
      ...theme.spacing.padding(theme.values.borderWidth.small),
      borderRadius: theme.borderRadius.tiny,
    },
    icon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
  })),
  medium: createActionIconStyle((theme) => ({
    touchableWrapper: {
      width: theme.values.itemHeight.small,
      height: theme.values.itemHeight.small,
      ...theme.spacing.padding(theme.values.borderWidth.small),
      borderRadius: theme.borderRadius.tiny,
    },
    icon: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
  })),
  large: createActionIconStyle((theme) => ({
    touchableWrapper: {
      width: theme.values.itemHeight.default,
      height: theme.values.itemHeight.default,
    },
  })),
  'iconSize:1': createActionIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
  })),
  'iconSize:2': createActionIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
  })),
  'iconSize:3': createActionIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
  })),
  'iconSize:7': createActionIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[7],
      height: theme.values.iconSize[7],
    },
  })),
  floating: createActionIconStyle((theme) => ({
    touchableWrapper: {
      borderRadius: theme.borderRadius.rounded,
    },
  })),
  flat: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral10,
    },
    touchableWrapper: {
      backgroundColor: theme.colors.neutral2,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    'touchableWrapper:disabled': {
      backgroundColor: theme.colors.neutral2,
    },
  })),
  'primary:outline': createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.primary3,
    },
    touchableWrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.primary3(theme.values.borderWidth.small),
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    'touchableWrapper:disabled': {
      backgroundColor: theme.colors.neutral3,
      ...theme.border.primary3(theme.values.borderWidth.small),
    },
  })),
  minimal: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.primary5,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    touchableWrapper: {
      backgroundColor: 'transparent',
      ...theme.spacing.padding(0),
    },
    'touchableWrapper:disabled': {
      backgroundColor: theme.colors.neutral1,
    },
  })),
  destructive: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral1,
    },
    touchableWrapper: {
      backgroundColor: theme.colors.destructive2,
    },
  })),
  'destructive:flat': createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.destructive2,
    },
    touchableWrapper: {
      backgroundColor: theme.colors.neutral2,
    },
  })),
  'destructive:minimal': createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.destructive2,
    },
    touchableWrapper: {
      backgroundColor: theme.colors.neutral1,
    },
  })),
  selected: createActionIconStyle((theme) => ({
    touchableWrapper: {
      backgroundColor: theme.colors.neutral2,
    },
  })),
  neutral8: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral8,
    },
  })),
  originalColor: createActionIconStyle((theme) => ({
    icon: {
      color: 'unset',
    },
  })),
  primary3: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.primary3,
    },
  })),
  primary5: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.primary5,
    },
  })),
  destructive2: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.destructive2,
    },
  })),
  positive2: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.positive2,
    },
  })),
  neutral1: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral1,
    },
  })),
  neutral2: createActionIconStyle((theme) => ({
    touchableWrapper: {
      backgroundColor: theme.colors.neutral2,
    },
    icon: {
      color: theme.colors.neutral8,
    },
  })),
  neutral7: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral7,
    },
  })),
  neutral9: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral9,
    },
  })),
  neutral10: createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral10,
    },
  })),
  sectionFiltersCloseModalIcon: createActionIconStyle((theme) => ({
    touchableWrapper: {
      backgroundColor: theme.colors.neutral1,
    },
    icon: {
      color: theme.colors.neutral10,
    },
  })),
  transparent: createActionIconStyle((theme) => ({
    touchableWrapper: {
      backgroundColor: 'transparent',
    },
  })),
  filterIcon: createActionIconStyle((theme) => ({
    touchableWrapper: {
      backgroundColor: theme.colors.neutral1,
    },
    icon: {
      color: theme.colors.neutral10,
    },
  })),
  'size:1': createActionIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
    touchableWrapper: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
  })),
  'size:3': createActionIconStyle((theme) => ({
    touchableWrapper: {
      width: theme.values.iconSize[6],
      height: theme.values.iconSize[6],
    },
  })),
  'icon:disabled': createActionIconStyle((theme) => ({
    icon: {
      color: theme.colors.neutral6,
    },
  })),
  normalize: createActionIconStyle((theme) => ({
    'touchableWrapper': {
      width: 'auto',
      height: 'auto',
      padding: 0,
      margin: 0,
      borderRadius: 0,
    },
  })),
  noPaddingHorizontal: createActionIconStyle((theme) => ({
    'touchableWrapper': {
      width: 'auto',
      paddingHorizontal: theme.spacing.value(0),
      marginHorizontal: theme.spacing.value(0),
    },
  })),
  historyIcon: createActionIconStyle((theme) => ({
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
    touchableWrapper: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
      borderRadius: theme.borderRadius.nanoish,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
    },
  })),
}
