import { variantProvider, React, AppForms } from '@/app'
import { DropzoneRef, DropzoneProps, ModalProps } from '@codeleap/web'
import { AnyFunction, ModalComposition, StylesOf, TypeGuards, onUpdate, useMemo, useRef, useState } from '@codeleap/common'
import { Button, Modal, Pager, Text, View, useUploadFilesContext } from '@/components'
import { Scanning, SelectFiles, StepPage, FilesOverview } from './Steps'
import { FileUtils } from '@/utils'
import { APIClient } from '@/services'
import { FileScanResponse } from '@/types'

export type UploadFilesModalProps = {
  ref?: React.MutableRefObject<DropzoneRef>
  visible?: boolean
  toggle?: React.Dispatch<React.SetStateAction<boolean>>
  showConfirmationStep?: boolean
  onResetForm?: AnyFunction
  onSubmit?: AnyFunction
  onSuccess?: AnyFunction
  onToggleModal?: AnyFunction
  renderFooter?: AnyFunction
  dropzoneProps?: Partial<DropzoneProps>
  modalProps?: Partial<ModalProps>
  malwareProtection?: boolean
  onSendError?: AnyFunction
  shouldResetFlowOnSubmit?: boolean
  initialStep?: Step['key']
  files?: File[]
  validate?: { valid: boolean; message: string | string[] }
  showImageStatement?: boolean
}

type Step = {
  key: 'select_files' | 'malware_verification' | 'files_overview'
  title: string | React.ReactNode
  modalStyles?: StylesOf<ModalComposition>
  children: React.ReactNode
  footer?: React.ReactNode
  goNext?: AnyFunction
  jump?: boolean
}

type ScanManagerProps = {
  files: File[]
  goToStepByKey: (key: Step['key']) => void
  onSendError?: AnyFunction
  setPrevFiles?: (p: File[]) => void
}

const ScanManager = (props: ScanManagerProps) => {
  const {
    files,
    goToStepByKey,
    onSendError: _onSendError,
    setPrevFiles,
  } = props

  const {
    setScanId,
    scanId,
    scanCompleted,
    toggleScanCompleted,
    setScanResults,
  } = useUploadFilesContext()

  APIClient.Articles.useCheckFilesScan({
    scan: scanId,
    enable: !scanCompleted,
    onComplete: onCompleteScan,
  })

  function onCompleteScan(results: FileScanResponse['results']) {
    setScanId(null)
    toggleScanCompleted(false)
    setScanResults(results)
    goToStepByKey('files_overview')
  }

  onUpdate(() => {
    if (files?.length === 0) {
      setPrevFiles([])
      return
    }
  }, [files])

  return null
}

const FooterButton = ({ title, onPress, disabled }: { title: string; disabled: boolean; onPress: () => void }) => {
  return (
    <Button
      variants={['large', 'marginTop:3', 'marginHorizontal:auto']}
      text={title}
      debugName={`UploadFilesModal:${title}`}
      id={`UploadFilesModal:${title?.toLowerCase()?.replaceAll(' ', '_')}`}
      onPress={onPress}
      debounce={1500}
      disabled={disabled}
    />
  )
}

const UploadFilesComponent = (props: UploadFilesModalProps, ref) => {

  const {
    onResetForm,
    onSubmit,
    onToggleModal,
    renderFooter,
    dropzoneProps,
    modalProps,
    onSendError,
    showConfirmationStep,
    visible,
    toggle,
    files,
    initialStep,
    shouldResetFlowOnSubmit,
    onSuccess,
    validate,
    showImageStatement = false,
  } = props
  const dropzoneRef = ref || useRef<DropzoneRef>(null)

  const {
    clearFiles: clearContextFiles,
    currentModal,
    toggleMiddleStepsVisible,
    scanResults,
    reducerState,
    reducerDispatch,
    resetScanFlow,
    isMiddleStepsVisible,
    setScanId,
  } = useUploadFilesContext()

  const hasError = scanResults?.some((malwareResult) => malwareResult.result === 'infected')

  const acceptedFiles = reducerState?.[currentModal]?.acceptedFiles
  const setAcceptedFiles = (internalAcceptedFiles) => reducerDispatch({ type: 'SET_ACCEPTED_FILES', payload: { files: internalAcceptedFiles, fileType: currentModal, multiple: dropzoneProps.multiple }})

  const rejectedFiles = reducerState?.[currentModal]?.rejectedFiles
  const setRejectedFiles = (interanlRejectedFiles) => reducerDispatch({ type: 'SET_REJECTED_FILES', payload: { files: interanlRejectedFiles, fileType: currentModal }})

  const [stepIndex, setStepIndex] = useState(0)
  const [prevFiles, setPrevFiles] = useState([])

  const [controller, setController] = useState(null)

  const allFilesExist = useMemo(() => {
    if (!acceptedFiles && !prevFiles) return false
    const prevFilesSet = new Set(prevFiles?.map(file => file.name))
    return acceptedFiles?.every(file => prevFilesSet.has(file.name))
  }, [prevFiles, acceptedFiles])

  const resetForm = () => {
    dropzoneRef.current?.clear()
    onResetForm?.()
  }

  const clearFiles = () => {
    clearContextFiles()
  }

  const resetMalwareFlow = () => {
    resetForm()
    goToStepByKey('select_files')
    resetScanFlow()
  }

  const uploadFile = async () => {
    onSubmit?.()
    if (onSuccess) {
      if (shouldResetFlowOnSubmit) {
        resetMalwareFlow()
      }
      onSuccess?.()
    } else {
      setTimeout(() => resetMalwareFlow(), 500)
    }
  }

  const continueUpload = async (files?: File[]) => {
    const abortController = new AbortController()
    const uploadFiles = acceptedFiles?.length ? acceptedFiles : files
    setPrevFiles(uploadFiles)
    toggleMiddleStepsVisible(true)
    goToStepByKey('malware_verification')
    setController(abortController)
    const scanId = await FileUtils.scanFiles(uploadFiles, abortController.signal, onSendError)
    if (scanId) {
      setScanId(scanId)
    }
  }

  const onToggle = () => {
    resetMalwareFlow()
    toggle?.(false)
    resetForm?.()
    onToggleModal?.()
    goToStepByKey('select_files')
  }

  const goToStepByKey = (key: Step['key']) => {
    const goTo = steps.findIndex((step) => step.key === key)
    setStepIndex(goTo)
  }

  const getStepField = (field: keyof Step) => {
    return steps[stepIndex][field]
  }

  onUpdate(() => {
    if (visible && initialStep) {
      setAcceptedFiles(files)
      continueUpload(files)
    }
  }, [visible, files?.length, initialStep])

  onUpdate(() => {
    if (!visible && !!controller) {
      controller.abort()
    }
  }, [visible])

  const Footer = () => {

    if (TypeGuards.isFunction(renderFooter)) {
      return renderFooter()
    }

    if (acceptedFiles?.length && allFilesExist) {
      return <FooterButton title='Upload' disabled={!validate?.valid} onPress={uploadFile} />
    }

    if (acceptedFiles?.length && !allFilesExist) {
      return <FooterButton title='Continue' onPress={continueUpload} />
    }

    return <FooterButton title='Select file' onPress={() => dropzoneRef.current?.open()} />
  }

  const steps: Step[] = [
    {
      key: 'select_files',
      title: 'Upload files',
      children: (
        <View variants={['column']}>
          <SelectFiles
            ref={dropzoneRef}
            icon={'add-file'}
            withImagePreview={false}
            acceptedFiles={acceptedFiles}
            setAcceptedFiles={setAcceptedFiles}
            rejectedFiles={rejectedFiles}
            setRejectedFiles={setRejectedFiles}
            onDrop={setAcceptedFiles}
            validator={(f) => AppForms.fileSizeValidator(f)}
            scannedFiles={scanResults}
            dropzoneProps={dropzoneProps}
          />
          {acceptedFiles?.length && allFilesExist && scanResults?.length && !validate?.valid ? (
            <View variants={['fullWidth', 'center', 'marginTop:2']}>
              {TypeGuards.isArray(validate?.message) ? (
                <View variants={['column', 'marginTop:0.5', 'gap:1']}>
                  {validate.message.map((message) => (
                    <Text variants={['p3', 'color:destructive2', 'textCenter']} text={message} />
                  ))}
                </View>
              ) : (
                <Text variants={['p3', 'color:destructive2', 'textCenter']} text={validate?.message} />
              )}
            </View>
          ) : null}
          <Footer />
        </View>
      ),
    },
    {
      key: 'malware_verification',
      title: 'Antivirus and malware verification',
      jump: !isMiddleStepsVisible,
      modalStyles: {
        box: {
          ...styles.verificationBox,
        },
      },
      children: (
        <Scanning />
      ),
    },
    {
      key: 'files_overview',
      title: 'Antivirus and malware verification',
      modalStyles: {
        box: {
          ...styles.verificationBox,
        },
      },
      children: (
        <View variants={['column']}>
          <FilesOverview results={scanResults} />
          <Button
            debugName='FilesOverview:Back'
            text='Continue'
            variants={['large', 'marginTop:3', 'marginHorizontal:auto']}
            onPress={() => {
              toggleMiddleStepsVisible(false)
              if (hasError) resetMalwareFlow()
              if (showConfirmationStep) goToStepByKey('select_files')
              else uploadFile()
            }}
          />
        </View>
      ),
    },
  ]

  const currentStepComponent = steps[stepIndex].children

  return (
    <>
      <Modal
        zIndex={999}
        visible={visible}
        styles={{
          box: styles.box,
          title: styles.title,
        }}
        title={getStepField('title')}
        variants={['centered']}
        description={
          showImageStatement && <Text variants={[`p1`]} text='Please submit the images for the manuscript separately.'/>
        }
        toggle={onToggle}
        onClose={resetMalwareFlow}
        {...modalProps}
      >

        <StepPage>
          {currentStepComponent}
        </StepPage>

      </Modal>

      {visible ? (
        <ScanManager
          files={acceptedFiles}
          goToStepByKey={goToStepByKey}
          onSendError={() => {
            onSendError?.()
            clearFiles()
          }}
          setPrevFiles={setPrevFiles}
        />
      ) : null}
    </>
  )
}

export const UploadFilesModal = React.forwardRef(UploadFilesComponent) as unknown as (props: UploadFilesModalProps, ref?: UploadFilesModalProps['ref']) => JSX.Element

const WIDTH = 900

const styles = variantProvider.createComponentStyle((theme) => ({
  box: {
    maxWidth: WIDTH,
    width: '100%',
    overflow: 'visible',
    ...theme.spacing.padding(4),
    [theme.media.down('mid')]: {
      ...theme.spacing.padding(2),
    },
  },
  verificationBox: {
    maxWidth: 900,
  },
  btnText: {
    marginRight: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  title: { margin: 0 },
}), true)
