import { createForm, yup } from '@codeleap/common'

export const reviewerValidationRules = {
  full_name: {
    min: 3,
    max: 30,
  },
  organisation: {
    min: 3,
    max: 250,
  },
}

export const reviewerForm = createForm('createManualInvite', {
  full_name: {
    type: 'text',
    label: 'Full name',
    placeholder: 'Enter a name',
    validate: yup.string()?.trim().min(reviewerValidationRules.full_name.min, `Minimum of ${reviewerValidationRules.full_name.min} characters`)
      .max(reviewerValidationRules.full_name.max)
      .nullable()
      .required(),
  },
  organisation: {
    type: 'text',
    label: 'Organisation',
    placeholder: 'Enter a organisation',
    validate: yup.string()?.trim().min(reviewerValidationRules.organisation.min, `Minimum of ${reviewerValidationRules.organisation.min} characters`)
      .max(reviewerValidationRules.organisation.max)
      .nullable()
      .required(),
  },
  email: {
    type: 'text',
    label: 'Email',
    placeholder: 'example@email.co.uk',
    validate: yup.string()?.trim().email()
      .required(),
  },
  tags: {
    type: 'select',
    label: 'Scientific fields',
    placeholder: 'Search a field',
    multiple: true,
    searchable: true,
    validate: (value) => ({ valid: value?.length > 0, message: 'Scientific fields are required' }),
  },
})
