import { PublicationStatus } from '@/types'
import { TagProps, Tag } from '@codeleap/web'
import { statusColorMap } from './colors'

type StatusTagProps = Partial<TagProps> & {
  status: keyof typeof PublicationStatus
}

export const StatusTag = (props: StatusTagProps) => {
  const { status, variants = [] } = props

  const statusColor = statusColorMap[status] || ''

  return (
    <Tag {...props} text={PublicationStatus[status]} variants={['statusTag', statusColor, ...variants]} />
  )
}
