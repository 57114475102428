import { Settings } from '@/app'
import { Button, useUploadFilesContext, View } from '@/components'
import { AppStatus } from '@/redux'
import { Publication } from '@/types'

export const NewPublicationVersion = ({ publication }: { publication: Publication }) => {

  const { setPublicationId } = useUploadFilesContext()

  function onUploadNewVersion() {
    AppStatus.setModal(['submitNewPublicationVersion', true, {
      data: {
        publication: publication?.id,
        onSubmit: (setAttachments) => {
          setAttachments(null)
        },
        dropzoneProps: {
          accept: Settings.FilePickerConfigs.CreatePublication.acceptFiles,
        },
      },
    }])

  }

  return (
    <View variants={['center']}>
      <Button
        text={`Send new version to editor`}
        debugName={`Send new publication version to editor button press!`}
        icon={'send'}
        disabled={publication?.status !== 'revision_requested'}
        variants={['sendNewPublicationVersionButton']}
        onPress={() => {
          AppStatus.setModal(['reviewsOverview', false])
          setPublicationId(publication?.id)
          onUploadNewVersion()
        }}
      />
    </View>
  )
}
