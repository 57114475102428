import { View } from '@codeleap/web'
import { ToolBarProps } from './types'
import { ToolBarContextProvider } from './ToolbarContext'
import { ToolbarComponents } from './components'

export * from './Extensions'

export const ToolBar = (props: ToolBarProps & {hide?: boolean}) => {
  const { hide = false, ...toolbarOptions } = props

  return (
    <ToolBarContextProvider toolbarProps={toolbarOptions}>
      <View style={{ display: !hide ? 'initial' : 'none' }}>
        <ToolbarComponents.Content />
        <ToolbarComponents.LinkModal />
      </View>
    </ToolBarContextProvider>
  )
}

