/* eslint-disable max-len */
import { React, variantProvider } from '@/app'
import { Button, Text, View, PagePath, usePublicationForm, BlindfoldedToaster } from '@/components'

import { SummaryButtons } from './SummaryButtons'
import { APIClient } from '@/services'
import { removeFields } from '@/utils'
import { AppStatus } from '@/redux'

type PublicationHeaderProps = { path: string }

const fieldsToRemove = ['short_title', 'subcategory']

export const PublicationHeader = (props: PublicationHeaderProps) => {

  const {
    forms,
    publication,
    savePublication,
    canAuthorEdit,
    isSavePublicationEnabled,
    isRevision_requested,
    handleSubmitPublication,
    validateForms,
    canEditorEdit,
  } = usePublicationForm()

  const { profile, isEditor, isPublisher } = APIClient.Session.useSession()

  const canPerformBlindfoldedFlow = (isEditor || isPublisher) && publication?.journal?.requires_blindfolded_review

  const { detail, publication: publicationForm } = forms

  const toggleAlert = (data) => {
    AppStatus.setModal(['submitManuscriptAlert', true, {
      data: {
        errorTitle: data?.errorTitle,
        errorMessage: data?.errorMessage,
      },
    }])
  }

  const fieldsValues = [removeFields(publicationForm.values, fieldsToRemove), (detail.values)]

  return (
    <>
      <PagePath path={props.path} publication={{ id: publication?.id, name: 'Manuscript details' }} dynamicPath=':id' />
      <View
        variants={['fullWidth', 'row', 'justifySpaceBetween', 'alignCenter', 'gap:2']}
        responsiveVariants={{ largeish: ['column', 'alignStart'] }}
      >
        <View variants={['row', 'gap:1', 'alignCenter']}>
          {publication?.version > 1 ? <View style={styles.version}>
            <Text variants={['p2']} text={'Version ' + publication.version} />
          </View> : null}

          <Text variants={['h1', 'ellipsis']} text={'Manuscript details'} style={styles.pageTitle} />
        </View>
        {canAuthorEdit ? (
          <View variants={['gap:2']} responsiveVariants={{ mid: ['fullWidth'] }}>
            {!isRevision_requested ? <Button
              debugName={'Save manuscript'}
              variants={['small', 'center', 'paddingHorizontal:4', 'flat']}
              text={'Save'}
              onPress={savePublication}
              disabled={!isSavePublicationEnabled?.[profile?.current_role]}
            /> : null}
            <Button
              debugName={'Submit manuscript'}
              variants={['small', 'center', 'paddingHorizontal:4', isRevision_requested ? 'flat' : '']}
              text={isRevision_requested ? 'Save' : 'Submit'}
              onPress={() => handleSubmitPublication(validateForms, toggleAlert, fieldsValues)}
            />
          </View>
        ) : null}
        {(isEditor || isPublisher) ? (
          <View variants={['gap:2']} responsiveVariants={{ mid: ['fullWidth'] }}>
            <SummaryButtons.DownloadSummary publication={publication?.id} />

            <SummaryButtons.ViewSummary publication={publication?.id} />

            {canEditorEdit ? (
              <Button
                debugName={'Save publication'}
                variants={['small', 'center', 'paddingHorizontal:2']}
                styles={{ wrapper: styles?.saveButton, text: styles?.saveButtonTxt }}
                text={'Save'}
                onPress={savePublication}
                disabled={!isSavePublicationEnabled?.[profile?.current_role]}
              />
            ) : null}
          </View>
        ) : null}
      </View>
      {canPerformBlindfoldedFlow ? <BlindfoldedToaster publication={publication?.id} /> : null}
    </>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  pageTitle: {
    ...theme.spacing.marginRight('auto'),
  },
  version: {
    ...theme.spacing.padding(0.75),
    color: theme.colors.neutral8,
    borderRadius: theme.borderRadius.tiny,
    ...theme.border.neutral8({ width: 1 }),
  },
  saveButton: {
    width: 'max-content',
  },
  saveButtonTxt: {
    fontWeight: '600',
  },
}), true)
