/* eslint-disable max-len */
import { React } from '@/app'
import { View, Text, usePublicationForm, Checkbox, Modal, TextEditorExtensions, TextEditor, ActivityIndicator } from '@/components'
import { APIClient } from '@/services'
import { Disclaimer } from '@/types'
import { PropsOf, onUpdate } from '@codeleap/common'
import { useEditor } from '@tiptap/react'

type DisclaimerModalProps = PropsOf<typeof Modal> & {
  disclaimer?: Disclaimer
}

const DisclaimerModal = ({ disclaimer, ...rest }: DisclaimerModalProps) => {
  const editor = useEditor({ content: disclaimer?.body, editable: false, extensions: TextEditorExtensions })

  onUpdate(() => {
    if (editor && disclaimer?.body) {
      editor?.commands?.setContent(disclaimer?.body)
    }
  }, [disclaimer?.body, editor])

  return (
    <Modal {...rest} title={disclaimer?.title} styles={{
      body: {
        minWidth: 700,
      },
    }}>
      <View variants={['column']}>
        <TextEditor
          editor={editor}
          toolbarComponent={null}
          variants={['flat', 'announcements']}
        />
      </View>
    </Modal>
  )
}

export const DisclaimerForm = () => {
  const { disclaimers, acceptedDisclaimers, handleDisclaimerAcceptance, isSaved_in_drafts } = usePublicationForm()
  const { isEditor, isPublisher } = APIClient.Session.useSession()
  const [selectedDisclaimer, setSelectedDisclaimer] = React.useState<Disclaimer | null>(null)

  const toggle = () => {
    setSelectedDisclaimer(null)
  }

  const showValidationMessage = disclaimers
    ?.some(disclaimer => {
      return acceptedDisclaimers?.[disclaimer?.id] !== true
    })

  if (!disclaimers?.length) {
    return (
      <View variants={['fullWidth', 'center', 'column', 'marginBottom:5']}>
        <ActivityIndicator variants={['fullWidth', 'center']} debugName='disclaimers activity indicator' />
        <Text variants={['p1']} text='Loading disclaimers...'/>
      </View>
    )
  }

  return (
    <View variants={['gap:1.25', 'column']}>
      {disclaimers?.length && !(isEditor || isPublisher) ? <Text variants={[`h5`, 'row', 'color:neutral8']} text='By submitting this manuscript you agree with'/> : null}
      {disclaimers.map((disclaimer) => {
        const handleLinkPress = () => {

          if (!!disclaimer.url) {
            window.open(disclaimer.url, '_blank')
            return
          }

          setSelectedDisclaimer(disclaimer)
        }

        return (
          <View key={disclaimer?.id} variants={['row', 'gap:2', 'alignStart']}>
            <Checkbox
              debugName={`author disclaimer ${disclaimer?.id}`}
              variants={['noError']}
              value={acceptedDisclaimers?.[disclaimer?.id]}
              onValueChange={(vl) => handleDisclaimerAcceptance(disclaimer?.id, vl)}
              disabled={!isSaved_in_drafts}
            />
            <Text
              text={disclaimer?.title}
              variants={['link', 'p1', 'regular', 'color:neutral10', 'cursor:pointer']}
              // style={{ display: 'contents' }}
              onPress={handleLinkPress}
            />
          </View>
        )
      })}
      {showValidationMessage && <Text variants={['p3', 'color:destructive2']} text='You must accept all disclaimers to submit the manuscript' />}

      <DisclaimerModal visible={!!selectedDisclaimer} toggle={toggle} disclaimer={selectedDisclaimer} />
    </View>
  )
}
