import { variantProvider } from '@/app'
import { Button, Icon, Modal, Text, View } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { downloadFileFromURL, useGetStyles } from '@/utils'

export function BlindfoldedFiles() {
  const { visible, data } = useAppSelector((s) => s.AppStatus.modals.blindfoldedFiles)
  const files = data?.changes?.files || []

  const { getStyles } = useGetStyles(styles)

  return (
    <Modal
      visible={visible}
      title={`Blinded review files version ${data?.changes?.version || 1}`}
      styles={getStyles('modal')}
      variants={['centered']}
      toggle={() => AppStatus.setModal(['blindfoldedFiles', false, { data: {}}])}
    >
      {files?.map((file, i) => (
        <View css={styles.fileWrapper} key={`${file?.filename}-${i}`}>
          <Icon name='file' style={styles.icon} debugName={`icon${file?.filename}`} />
          <View variants={['column', 'gap:0.5', 'flex']} style={styles.textWrapper}>
            <Text variants={['p1', 'breakWord']} text={file?.filename} />
            <Text variants={['p3', 'color:neutral7', 'wrap']} text={file?.category} />
          </View>
          <Button
            styles={getStyles('button')}
            text='Download'
            icon='download'
            debugName={`download button ${file.filename}`}
            onPress={() => downloadFileFromURL(file.file, 'blinded_review_file', true)}
          />
        </View>
      ))}
    </Modal>
  )
}

const MODAL_WIDTH = 800

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    modalBox: {
      maxWidth: MODAL_WIDTH,
      ...theme.presets.fullWidth,
    },
    modalTitle: {
      ...theme.spacing.margin(0),
    },
    textWrapper: {
      maxWidth: 500,
      overflow: 'hidden',
    },
    fileWrapper: {
      ...theme.spacing.padding(2),
      ...theme.presets.alignCenter,
      ...theme.spacing.gap(2),
      ...theme.border.neutral3({ width: 1, directions: ['bottom'] }),

      '&:last-of-type': {
        border: 'none',
      },
    },
    icon: {
      ...theme.sized(2.5),
      flexShrink: 0,
    },

    buttonWrapper: {
      height: theme.values.itemHeight.default,
      marginLeft: 'auto',
    },
  }),
  true,
)
