import { api } from '@/app'
import { Publication } from '@/types/publication'

const BASE_URL = 'article/'

type ArticleSummary = {
  url: string
  status: 'done' | 'processing'
}

export const getArticleSummary = async (id: Publication['id']) => {
  const response = await api.get<ArticleSummary>(`${BASE_URL}summary/`, {
    params: {
      article: id,
    },
  })
  return response.data
}

export const deleteReviewer = async (data) => {
  const response = await api.post(`${BASE_URL}delete_reviewer/`, data)

  return response.data
}

export * from './plagiarism'
export * from './files'
