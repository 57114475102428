import { fileSizeValidator } from '@/app/forms'
import { AttachmentsDropzone, Publication, usePublicationForm } from '@/components'
import { useFileCategoryOptions } from '@/utils'
import { useCallback, useMemo } from '@codeleap/common'
import { EmptyPlaceholder } from '@codeleap/web'

export const AttachmentsForm = () => {

  const {
    publication,
    attachments,
    setAttachments,
    setMediaDeleteArray,
    attachmentsMedia,
    setAttachmentsMedia,
    canAuthorEdit,
    isPublicationEditable,
    isSaved_in_drafts,
  } = usePublicationForm()

  const file_categories = useFileCategoryOptions({ version: publication?.version, status: publication?.status })

  const categoriesWithoutMainDocument = useMemo(() => file_categories?.filter(c => !c.refers_to_main_document), [file_categories])

  const FilePreviewComponent = useCallback(
    (props) => {
      return (
        <Publication.FilePreview
          {...props}
          file={props?.file}
          setAttachments={setAttachments}
          fileCategories={categoriesWithoutMainDocument}
          disabled={!isSaved_in_drafts}
          variants={['ellipsis', 'media']}
        />
      )
    },
    [setAttachments, categoriesWithoutMainDocument],
  )

  const onRemoveAttachment = (media) => {
    if (media?.id) setMediaDeleteArray((prev) => [...prev, media.id])
  }

  if (!isPublicationEditable && !attachmentsMedia.length) {
    return <EmptyPlaceholder icon='file' variants={['table']} title='No Attachments found.' />
  }

  return (
    <AttachmentsDropzone
      showTable={!!attachmentsMedia?.length}
      showDropzone={!!canAuthorEdit}
      showDelete={canAuthorEdit}
      showDownload={!canAuthorEdit}
      canChangeCategory={canAuthorEdit}
      showFileCategory
      publication={publication?.id}
      currentAttachments={attachments}
      setCurrentAttachments={setAttachments}
      tableAttachments={attachmentsMedia}
      setTableAttachments={setAttachmentsMedia}
      onDelete={onRemoveAttachment}
      disabled={!isSaved_in_drafts}
      showDelete={isSaved_in_drafts}
      dropzoneProps={{
        FilePreviewComponent,
        validator: fileSizeValidator,
        disabled: !isSaved_in_drafts,
      }}
    />
  )
}
