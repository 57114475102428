import { FileCategories, Profile } from '@/types'
import { queryClient } from './queryClient'

export const QueryKeys = {
  me: queryClient.queryKey<Profile>(['me']),
  create: queryClient.queryKey(['createProfile']),
  update: queryClient.queryKey(['updateProfile']),
  region: queryClient.dynamicQueryKey(query => ['region', query]),
  category: queryClient.queryKey(['category']),
  subcategory: queryClient.queryKey(['subcategory']),
  fileCategory: queryClient.queryKey<FileCategories>(['fileCategory']),
  scientificTag: queryClient.queryKey(['scientificTag']),
  emailVariables: queryClient.queryKey(['emailVariables']),
  myPermissions: queryClient.dynamicQueryKey(journal => ['myPermissions', journal]),
  articleType: queryClient.dynamicQueryKey(query => ['articletype', query]),
  title: queryClient.queryKey(['title']),
  gender: queryClient.queryKey(['gender']),
  plagiarismEula: queryClient.queryKey(['plagiarismEula']),
}
