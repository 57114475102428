import { variantProvider } from '@/app'
import {
  Button,
  Navigation as NavigationComponent,
  View,
  JournalMenuItem,
  openUploadPublicationFiles,
  useUploadFilesContext,
  usePublicationForm,
  reviewerInitialState,
  authorInitialState,
} from '@/components'
import { APIClient } from '@/services'
import { useMemo } from 'react'
import { authorItems, editorItems, publisherItems, reviewerItems } from './MenuItems'
import { Navigation } from '@/utils'
import { Badge } from '@codeleap/web'
import { useLocation } from '@reach/router'

const MANUSCRIPTS_WITH_ID_PATH_REGEX = /^\/manuscripts\/\d+\/$/

export const NavigationMenu = () => {
  const { isEditor, isPublisher, isAuthor, isReviewer, hasPermission } = APIClient.Session.usePermissions()
  const { profile } = APIClient.Session.useSession()
  const { badges } = APIClient.Badges.useBadges(profile)
  const publications = APIClient.Publications.publicationsManager.useCreate()
  const attachments = APIClient.Publications.mediaManager.useCreate()
  const { setCurrentModal } = useUploadFilesContext()
  const { cleanPublication } = usePublicationForm()

  const openPublicationsModal = () => {
    setCurrentModal('manuscripts')
    openUploadPublicationFiles({
      create: publications.create,
      createAttachments: attachments.create,
      dropzoneProps: { multiple: false },
      resetPreviousPublication: () => {
        cleanPublication?.()
        return
      },
      showImageStatement: true,
    })
  }

  const _menuItems = useMemo(() => {
    if (isAuthor) return authorItems
    if (isReviewer) return reviewerItems
    if (isEditor) return editorItems
    if (isPublisher) return publisherItems
    return []
  }, [profile?.current_publisher, profile?.current_role, hasPermission])

  return (
    <NavigationComponent.Group style={styles.wrapper}>
      <View style={styles.group}>
        {isAuthor ? (
          <JournalMenuItem
            onPress={() => Navigation.navigate('Journals.List', { route: 'view/' + profile?.current_journal })}
            journalId={profile?.current_journal} variants={['marginBottom:3']} />
        ) : null}

        {isAuthor ? <Button
          variants={['fullWidth', 'center:all', 'height:default', 'menu', 'backgroundColor:neutral10']}
          debugName='Submit Manuscript from Layout'
          text='New Manuscript'
          icon={'plus-circle'}
          onPress={openPublicationsModal}
        /> : null}

        {_menuItems.map((item, i) => {
          const showBadge = (item?.text === 'Manuscripts' && (badges?.manuscripts || badges?.history)) || (item?.text === 'Invites' && badges?.invites)
          return (
            <View variants={['flex', 'relative', 'border-radius:tiny']}>
              <NavigationComponent.Button
                key={item.text + i}
                variants={['menu', 'fullWidth']}
                styles={{
                  itemWrapper: styles.navigationButton,
                }}
                onPress={() => null}
                {...item}
              />
              {showBadge ? <Badge variants={['border', 'position:rightTop']} badge /> : null}
            </View>
          )
        },
        )}
      </View>

    </NavigationComponent.Group>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    ...theme.presets.fullWidth,
    [theme.media.down('mid')]: {
      justifyContent: 'center',
    },
  },
  group: {
    maxWidth: theme.values.sideBarWidth,
    width: '100%',
    ...theme.spacing.gap(2),
    ...theme.values.flex,
    ...theme.presets.column,
    flexShrink: 0,
    [theme.media.down('mid')]: {
      width: '100%',
    },
  },
  navigationButton: {
    borderRadius: theme.borderRadius.tiny,
    width: '100%',
  },

}), true)
